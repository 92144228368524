<template>
  <a-modal :width="width" :maskClosable="false" v-model="visible" :title="title" :confirm-loading="submitLoading"
    @ok="onSubmit" @cancel="handleCancel">
    <a-form-model :rules="rules" :model="form" ref="formModel" size="small">
      <a-form-model-item v-for="(item, index) in formItemsData" :key="index" :label="item.label" :prop="item.field">
        <template v-if="item.type == 'tree'">
          <a-tree checkable v-model="form[item.field]" :tree-data="item.data" :replace-fields="item.replaceFields" />
        </template>
        <template v-else-if="item.type == 'html'">
          <wangeditor @change="editorChange" :field="item.field" :content="form[item.field]"></wangeditor>
        </template>
        <template v-else-if="item.type == 'img'">
          <div class="fs12 color666">{{ item.tips }}</div>
          <imgUpload :type="item.field" :uploadType="item.uploadType"
            :list="item.maxLength > 1 ? form[item.field] ? form[item.field] : [] : form[item.field] ? [form[item.field]] : []"
            :maxLength="item.maxLength" @remove="removeUpload" @result="resultUpload"></imgUpload>
        </template>
        <template v-else-if="item.type == 'select'">
          <a-select v-model="form[item.field]" :placeholder="'请选择' + item.label">
            <a-select-option v-for="(attr, index) in item.data" :value="attr[item.value]" :key="index">{{ attr[item.name]
            }}</a-select-option>
          </a-select>
        </template>
        <template v-else-if="item.type == 'password'">
          <a-input type="password" v-model="form[item.field]" :placeholder="'请输入' + item.label" />
        </template>
        <template v-else-if="item.type == 'textarea'">
          <a-textarea v-model="form[item.field]" :placeholder="'请输入' + item.label" />
        </template>
        <template v-else-if="item.type == 'spec'">
          <SpecTable :params="item" :data="form[item.field]"></SpecTable>
        </template>
        <template v-else>
          <a-input v-model="form[item.field]" :placeholder="'请输入' + item.label" />
        </template>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import imgUpload from "@/components/myUpload.vue"
import wangeditor from "@/components/wangeditor.vue"
import SpecTable from "@/components/SpecTable/SpecTable.vue"

export default {
  props: {
    width: String,
    visible: Boolean,
    title: String,
    form: Object,
    formItems: Array,
    rules: Object,
    submitLoading: Boolean,
  },
  data() {
    return {
      formData: {},
      formItemsData: []
    }
  },
  watch: {
    form: {
      handler(val) {
        this.formData = val
      },
      immediate: true,
      deep: true
    },
    formItems: {
      handler(val) {
        this.formItemsData = val
      },
      immediate: true,
      deep: true
    }
  },
  components: {
    imgUpload,
    wangeditor,
    SpecTable
  },
  computed: {
    imgArr() {
      return this.formItemsData.filter(item => item.type == 'img')
    },
    htmlArr() {
      return this.formItemsData.filter(item => item.type == 'html')
    }
  },
  methods: {
    // 取消
    handleCancel() {
      this.$emit("handleCancel")
    },
    // 提交
    onSubmit() {
      this.$refs.formModel.validate(async (valid) => {
        if (valid) {
          console.log(this.form, 11);
          this.$emit("onSubmit", this.form)
        } else {
          return false
        }
      })
    },

    // 图片删除
    removeUpload(row) {
      this.imgArr.forEach(item => {
        if (row.type == item.field) {
          if (item.maxLength > 1) {
            this.form[item.field].splice(row.index, 1)
          } else {
            this.form[item.field] = ""
          }
        }
      })
    },
    // 图片上传成功回调
    resultUpload(row) {
      this.imgArr.forEach(item => {
        if (row.type == item.field) {
          if (item.maxLength > 1) {
            this.form[item.field].push(row.url)
          } else {
            this.form[item.field] = row.url
          }
        }
      })
    },
    // 富文本
    editorChange(params) {
      let { html, field } = params
      this.form[field] = html
    },
  },
}
</script>

<style></style>