<template>
  <a-form-model size="small" layout="inline" :model="form">
    <a-form-model-item v-for="(item, index) in search" :key="index" :label="item.label">
      <template v-if="item.type == 'select'">
        <a-select class="w150" allowClear v-model="form[item.field]" :placeholder="'请选择' + item.label">
          <a-select-option v-for="(attr, index) in item.data" :value="attr[item.value]" :key="index">{{
            attr[item.name] }}</a-select-option>
        </a-select>
      </template>
      <template v-else-if="item.type == 'date'">
        <a-date-picker  class="w150" allowClear  v-model="form[item.field]" valueFormat="YYYY-MM-DD" format="YYYY-MM-DD" />
      </template>
      <template v-else-if="item.type == 'dateRange'">
        <a-range-picker
          :show-time="{ format: 'YYYY-MM-DD HH:mm:ss' }"
          valueFormat="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD HH:mm:ss"
          :placeholder="['开始时间', '结束时间']"
          v-model="form[item.field]"
        />
      </template>
      <template v-else>
        <a-input v-model="form[item.field]" :placeholder="'请输入' + item.label" />
      </template>
    </a-form-model-item>
    <a-form-model-item>
      <a-button icon="search" @click="onSubmit" type="primary">搜索</a-button>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
export default {
  props: ["searchItems"],
  data() {
    return {
      form: {},
      search: []
    }
  },
  watch: {
    searchItems: {
      handler(val) {
        this.search = JSON.parse(JSON.stringify(val))
      },
      immediate: true
    }
  },
  mounted() {
  },
  methods: {
    onSubmit() {
      this.$emit("getSearch", this.form)
    },
  },
}
</script>

<style></style>