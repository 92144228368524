<template>
    <div>
        <a-table :rowKey="'index'" :pagination="false" class="mt10" :dataSource="data" :columns="params.specColumns">
            <a-space v-for="(attr, aIndex) in params.specColumns" :key="aIndex" :slot="attr.scopedSlots.customRender"
                slot-scope="item,row,index">
                <template v-if="attr.type == 'button'">
                    <a-button size="small" @click="delSpec(index)" type="danger">删除</a-button>
                </template>
                <template v-else-if="attr.type == 'img'">
                    <imgUpload @remove="removeUpload" @result="resultUpload" :list="row[attr.key] ? [row[attr.key]] : []"
                        :maxLength="1" :type="'spec_' + index"></imgUpload>
                </template>
                <template v-else>
                    <a-input v-model="row[attr.key]" :placeholder="'请输入' + attr.title" />
                </template>
            </a-space>
        </a-table>
        <a-button type="primary" @click="addSpec">添加规格</a-button>
    </div>
</template>

<script>
import imgUpload from "@/components/myUpload.vue"

export default {
    props: ["params", "data"],
    components: {
        imgUpload
    },
    computed: {
        imgArr() {
            return this.params.specColumns?.filter(item => item.type == 'img')
        },
    },
    methods: {
        addSpec() {
            this.data.push(Object.assign({}, this.params.specTpl))
        },
        delSpec(index) {
            this.data.splice(index, 1)
        },
        removeUpload(row) {
            let index = row.type.split('_')[1]
            this.imgArr.forEach(item => {
                this.data[index][item.key] = ''
            })
        },
        resultUpload(row) {
            let index = row.type.split('_')[1]
            this.imgArr.forEach(item => {
                this.data[index][item.key] = row.url
            })
            console.log(this.data);
        },
    }
}
</script>

<style></style>