<!-- 广告管理 -->

<style scoped>
	.img{
		/* width:30px; */
		height:30px
	}
	.ellipsis-1{
		max-width: 180px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden; 
		word-wrap: break-word;
		 word-break: break-all 
	}
	.w150{
		width: 150px;
	}
</style>
<template>
  <div class="pageCentent">
    <div >
      <MyTable class="mt10" :isSelection="false" :apiUrl="apiUrl" :params="ajaxparams" ref="myTable" :columns="columns">
		 <a-space slot='button' slot-scope="item,row,index">
			<a-popconfirm
			v-if="row.state==0"
			title="确定已处理?"
			ok-text="确定"
			cancel-text="取消"
			@confirm="approved(row)" >
				<a-button size="small" type="primary" >
					处理
				</a-button>
			</a-popconfirm>
        </a-space>
		<a-space slot='location' slot-scope="item,row,index">
			<span>{{row.location=='Malaysia'?'马来西亚':'武夷山'}}</span>
        </a-space>
        <a-space slot='state' slot-scope="item,row,index">
			<span>{{row.state==0?'未处理':'已处理'}}</span>
        </a-space>
		<a-space slot='id_card' slot-scope="item,row,index">
			<span>{{$common.hiddenString(row.id_card,'********',6)}}</span>
        </a-space>
		
      </MyTable>
    </div>
	<a-modal :footer="null" v-model="dialogVisible" title="查看" >
		<img style="width:100%" :src="dialogImg" alt="">
	</a-modal>
  </div>
</template>
<script>
import MyTable from "@/components/myTable.vue"
import {userTravelRegistration_index,userTravelRegistration_changeState} from '@/api/users'
export default {
	components:{MyTable},
  data() {
    return {
		IMGHOST:window.IMGHOST,
		apiUrl:userTravelRegistration_index,
		ajaxparams:{},
		dialogImg:'',
		dialogVisible:false,
		columns:[
			{ title: '地点', ellipsis: true, width: 120, fixed: 'left', dataIndex: 'location', key: 'location' ,scopedSlots: { customRender: 'location' } },
			{ title: '姓名', ellipsis: true,dataIndex: 'user_name',fixed: 'left', key: 'user_name',width: 120,scopedSlots: { customRender: 'user_name' }},
			{ title: '手机号', ellipsis: true, width: 150, dataIndex: 'mobile', key: 'mobile' ,scopedSlots: { customRender: 'mobile' } },
			{ title: '身份证号码', ellipsis: true, width: 220, dataIndex: 'id_card', key: 'id_card' ,scopedSlots: { customRender: 'id_card' } },
			{ title: '人数', ellipsis: true, width: 120, dataIndex: 'num', key: 'num' ,scopedSlots: { customRender: 'num' }},
			{ title: '日期', ellipsis: true, width: 120, dataIndex: 'date', key: 'date' ,scopedSlots: { customRender: 'date' }},
			{ title: '状态', ellipsis: true,dataIndex: 'state', key: 'state',width: 100,scopedSlots: { customRender: 'state' }},
			{ title: '创建时间', ellipsis: true,dataIndex: 'create_at', key: 'create_at',width: 200},
			{ title: '操作', key: 'operation',fixed: 'right', width: 120, scopedSlots: { customRender: 'button' } }
		],
		
		
	}
  },
  created() {
  },
  mounted() {
  },
  destroyed() {  
  },
  methods: {
	showDialog(src){
		this.dialogImg = src
		this.dialogVisible = true
	},
	mobile(id_card){
		var mobile = id_card.substr(0, 6) + '*******' + id_card.slice(14)
		return mobile
	},
	
	approved(row){
		userTravelRegistration_changeState({id:row.id}).then((res)=>{
			this.$message.success('操作成功');
			setTimeout(()=>{
				this.confirmLoading = false;
				this.isModalShow = false
				this.$refs.myTable.getList()
			},1500)
		})
	},
	rejection(row){
		userApply_rejection({id:row.id}).then((res)=>{
			this.$message.success('操作成功');
			setTimeout(()=>{
				this.confirmLoading = false;
				this.isModalShow = false
				this.$refs.myTable.getList()
			},1500)
		})
	},
	
  },
}
</script>
	