<script>
import { setTimeout } from "timers"
export default {
  name: "MyTable",
  props: {
    msg: String,
    apiUrl: null,
    columns: {
      type: [Array, Object],
      default: [],
    },
    params: {
      type: [Object],
      default: () => {
        return {}
      },
    },
    isSelection: {
      type: [String, Boolean],
      default: true,
    },
    disableSelection: {
      type: Object,
      default: function () {
        return { key: "", value: "" }
      },
    },
    isRequest: {
      type: [String, Boolean],
      default: true,
    },
    selectedKeys: {
      type: [Array],
      default: function (params) {
        return []
      },
    },
    selectedKey: {
      type: String,
      default: "key",
    },
    isPagination: {
      type: [String, Boolean],
      default: true,
    },
    scroll: {
      type: Object,
      default: function () {
        return { x: "100%", y: 0 }
      },
    },
  },
  render() {
    const on = {
      expand: this.onExpand,
      ...this.$listeners,
    }
    const props = {
      ...this.$attrs,
      ...this.$props,
      ...{
        loading: this.loading,
        rowKey: this.selectedKey,
        dataSource: this.tableData,
        columns: this.columns,
        scroll: this.scroll,
        "expanded-row-keys.sync": this.expandedRowKeys,
        "row-selection": this.isSelection ? this.rowSelection : null,
        pagination: !this.isPagination
          ? false
          : {
            current: this.page,
            pageSize: this.limit, // 默认每页显示数量
            showSizeChanger: true, // 显示可改变每页数量
            pageSizeOptions: ["10", "20", "30", "40"], // 每页数量选项
            showTotal: (t) => `共 ${t} 条记录`, // 显示总数
            total: this.total,
            onShowSizeChange: this.showSizeChange, // 改变每页数量时更新显示
            onChange: this.paginationChange,
          },
      },
    }
    const table = (
      <a-table props={props} scopedSlots={this.$scopedSlots} on={on}></a-table>
    )
    return <div ref="my-table">{this.isShowList ? table : ""}</div>
  },
  data() {
    return {
      tableData: [],
      total: 0,
      limit: 20,
      page: 1,
      selectedRowKeys: [],
      loading: false,
      expandedRowKeys: [],
      isShowList: false,
    }
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
          this.$emit("selectRow", selectedRows)
        },
        getCheckboxProps: (record) => ({
          props: {
            disabled: this.disableSelection.key
              ? record[this.disableSelection.key] != this.disableSelection.value
              : false,
          },
        }),
        onSelect: (record, selected, selectedRows, nativeEvent) => {
          this.$emit("onSelect", {
            record,
            selected,
            selectedRows,
            nativeEvent,
          })
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          this.$emit("onSelectAll", { selected, selectedRows, changeRows })
        },
      }
    },
  },
  created() {
    this.selectedRowKeys = this.selectedKeys
    let tableData = []
    if (this.isRequest) {
      this.getList()
    }
  },
  methods: {
    updateSelectedRowKeys() {
      this.selectedRowKeys = this.selectedKeys
    },
    async getList() {
      this.loading = true
      this.params.page = this.page
      this.params.limit = this.limit
      this.apiUrl(this.params)
        .then((res) => {
          this.isShowList = false
          let result = res.list ? res.list : res ? res : []
          for (let i = 0; i < result.length; i++) {
            result[i].key = result[i].id ? result[i].id : i
          }
          setTimeout(() => {
            this.isShowList = true
            this.$emit("endRequest", res)
          }, 10)
          this.tableData = result
          this.total = res.count ? res.count : this.tableData.length
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },

    paginationChange(page, limit) {
      this.page = page
      this.limit = limit
      this.getList()
    },
    showSizeChange(page, limit) {
      this.page = 1
      this.limit = limit
      this.getList()
    },
    search() {
      this.page = 1
      this.$emit("selectRow", [])
      this.selectedRowKeys = []
      this.getList()
    },

    onExpand(expanded, record) {
      // let _this = this;
      // if(expanded){
      // 	this.expandedRowKeys = [];
      // 	this.expandedRowKeys.push(record.key)
      // 	this.$nextTick(()=>{
      // 		setTimeout(() => {
      // 			const element = document.querySelectorAll(`[data-row-key='${record.key}-extra-row']`)
      // 			const height = element[0].getBoundingClientRect().height - 33;
      // 			element[1].childNodes[0].innerHTML = `<div style="height: ${height}px;"></div>`
      // 		}, 0);
      // 	})
      // }else {
      // 	this.expandedRowKeys = [];
      // }
    },
  },
}
</script>

<style scoped></style>
