<!-- 账号管理 -->
<style scoped></style>
<template>
  <div>
    <div style="background-color: #fff;padding: 16px;margin-bottom: 20px;">
      <a-button type="primary" @click="dialogClick('add')">添加</a-button>
      <MyTable
        class="mt10"
        :apiUrl="spec_index"
        :params="ajaxparams"
        ref="myTable"
        :columns="columns"
      >
        <a-space slot="button" slot-scope="item,row,index">
          <a-button size="small" type="primary" @click="dialogClick('edit', row)">编辑</a-button>
          <a-popconfirm title="确定是否删除?" ok-text="确定" cancel-text="取消" @confirm="delConfirm(row)">
            <a-button size="small" type="danger">删除</a-button>
          </a-popconfirm>
        </a-space>
        <a-space slot="avatar" slot-scope="item,row,index">
          <img v-if="row.avatar" style="width:30px;heigth:30px" :src="IMGHOST + '/' + row.avatar" />
          <span v-else>-</span>
        </a-space>
        <a-space slot="goods_type" slot-scope="item,row,index">
          <div>{{goodsTypeData[item]}}</div>
        </a-space>
        <a-space slot="is_lock" slot-scope="item,row,index">
          <a-switch
            @click="switchClick(row, index)"
            :disabled="isDisabledSwitch"
            checked-children="启用"
            un-checked-children="禁用"
            :defaultChecked="row.is_lock == 1 ? true : false"
          />
        </a-space>
      </MyTable>
    </div>
    <a-modal
      :maskClosable="false"
      v-model="dialogVisible"
      :title="dialogType == 'add' ? '添加规格' : '编辑规格'"
      :confirm-loading="submitLoading"
      @ok="onSubmit"
      @cancel="handleCancel"
    >
      <a-form-model :rules="rules" ref="formModel" size="small" :model="form">
        <a-form-model-item label="商品类型" placeholder="请选择推荐">
          <a-select v-model="form.goods_type">
            <a-select-option v-for="(v,k) of goodsTypeData" :value="k" :key="k">{{v}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="规格名称" prop="name">
          <a-input placeholder="请输入规格名称" v-model="form.name" />
        </a-form-model-item>
        <a-form-model-item label="详细规格" prop="val">
          <a-input placeholder="请输入详细规格，用“,”隔开如：a,b,c" v-model="form.val" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import imgUpload from "@/components/myUpload.vue"
import MyTable from "@/components/myTable.vue"

import { spec_index, spec_add, spec_edit, spec_del } from "@/api/spec"
import { config_index } from "@/api/config"

export default {
  components: { MyTable, imgUpload },
  data() {
    return {
      spec_index: spec_index,
      IMGHOST: window.IMGHOST,
      ajaxparams: {}, //请求参数
      /*
				列表header
			*/
      columns: [
        {
          title: "操作",
          key: "operation",
          ellipsis: true,
          scopedSlots: { customRender: "button" },
        },
        {
          title: "商品类型",
          ellipsis: true,
          dataIndex: "goods_type",
          key: "goods_type",
          scopedSlots: { customRender: "goods_type" },
        },
        { title: "规格名称", ellipsis: true, dataIndex: "name", key: "name" },
        { title: "详细规格", dataIndex: "val", key: "val" },

        { title: "创建时间", ellipsis: true, dataIndex: "ctime", key: "ctime" },
      ],
      isDisabledSwitch: false, //是否禁用Switch
      submitLoading: false,
      dialogType: "add",
      dialogVisible: false,
      form: {
        id: 0,
        val: "",
        name: "",
      },
      positionData: [],
      loading: false,
      tableData: [],
      rules: {
        name: [{ required: true, message: "请输入规格名称", trigger: "blur" }],
        val: [{ required: true, message: "请输入详细规格", trigger: "blur" }],
      },
      goodsTypeData: ["请选择", "种子", "农药", "苗", "肥料", "其他"],
    }
  },
  created() {},
  methods: {
    handleCancel() {
      this.submitLoading = false
      this.dialogVisible = false
    },
    async onSubmit() {
      this.$refs.formModel.validate(async (valid) => {
        if (valid) {
          this.submitLoading = true
          if (this.dialogType == "add") {
            spec_add(this.form)
              .then((result) => {
                this.$message.success("操作成功")
                setTimeout(() => {
                  this.$refs.myTable.getList()
                  this.submitLoading = false
                  this.dialogVisible = false
                }, 1500)
              })
              .catch(() => {
                this.submitLoading = false
              })
          } else {
            spec_edit(this.form)
              .then((result) => {
                this.$message.success("操作成功")
                setTimeout(() => {
                  this.$refs.myTable.getList()
                  this.submitLoading = false
                  this.dialogVisible = false
                }, 1500)
              })
              .catch(() => {
                this.submitLoading = false
              })
          }
        } else {
          return false
        }
      })
    },
    async delConfirm(val) {
      //删除
      spec_del({ id: val.id }).then((result) => {
        this.$message.success("操作成功")
        setTimeout(() => {
          this.$refs.myTable.getList()
        }, 1500)
      })
    },
    dialogClick(type, row) {
      this.dialogType = type
      if (type == "edit") {
        this.form = {
          id: row.id,
          name: row.name,
          val: row.val,
          goods_type: row.goods_type,
        }
      } else {
        this.form = {
          name: "",
          val: "",
          goods_type: 1,
        }
      }
      this.dialogVisible = true
    },
  },
}
</script>
