<!-- 账号管理 -->
<style scoped>
.width150 {
  width: 150px;
}
</style>
<template>
  <div>
    <div style="background-color: #fff;padding: 16px;margin-bottom: 20px;">
      <div>
        <a-button type="primary" @click="doReady()">开单</a-button>
      </div>
      <MyTable
        class="mt10"
        :apiUrl="orders_index"
        :params="ajaxparams"
        ref="myTable"
        :columns="columns"
        @selectRow="onSelectChange"
      >
        <a-space slot="button" slot-scope="item,row,index">
          <a-popconfirm
            title="确定是否开单?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="doReady(row.id,index)"
          >
            <a-button size="small" type="primary">开单</a-button>
          </a-popconfirm>
        </a-space>
        <a-space slot="img" slot-scope="item,row,index">
          <img v-if="row.img" style="width:30px;heigth:30px" :src="IMGHOST + '/' + row.img" />
          <span v-else>-</span>
        </a-space>
      </MyTable>
    </div>
  </div>
</template>
<script>
import MyTable from "@/components/myTable.vue"
import { orders_unready_index, orders_doReady } from "@/api/orders"
export default {
  components: { MyTable },
  data() {
    return {
      orders_index: orders_unready_index,
      IMGHOST: window.IMGHOST,

      ajaxparams: {}, //请求参数
      /*
			列表header
		*/
      columns: [
        {
          title: "商品名称",
          ellipsis: true,
          width: 220,
          dataIndex: "goods_name",
          key: "goods_name",
        },
        {
          title: "单价",
          ellipsis: true,
          width: 100,
          dataIndex: "price",
          key: "price",
        },
        {
          title: "购买数量",
          ellipsis: true,
          width: 80,
          dataIndex: "num",
          key: "num",
        },
        {
          title: "规格",
          ellipsis: true,
          width: 300,
          dataIndex: "goods_spec_desc",
          key: "goods_spec_desc",
        },
        // {
        //   title: "规格图片",
        //   dataIndex: "img",
        //   key: "img",
        //   width: 100,
        //   scopedSlots: { customRender: "img" },
        // },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "ctime",
          key: "ctime",
          width: 220,
        },
        {
          title: "操作",
          key: "operation",
          width: 300,
          ellipsis: true,
          scopedSlots: { customRender: "button" },
        },
      ],
      selectGoods: [],
    }
  },
  methods: {
    onSelectChange(val) {
      console.log(val)
      if(val && val.length > 0){
        this.selectGoods = val.map(item => item.id)
      }
    },
    async doReady(id) {
      let ids
      if (id) {
        ids = [id]
      } else {
        if(this.selectGoods.length > 0){
          ids = this.selectGoods
        }else{
          this.$message.warning('请选择至少一个产品');
          return
        }
      }
      orders_doReady({ ids })
        .then((result) => {
          this.$message.success("操作成功")
          setTimeout(() => {
            this.$refs.myTable.getList()
          }, 1500)
        })
        .catch(() => {})
    },
  },
}
</script>
