<!-- 账号管理 -->
<style scoped></style>
<template>
  <div>
    <div style="background-color: #fff;padding: 16px;margin-bottom: 20px;">
      <!-- 搜索条件 -->
      <a-form-model size="small" layout="inline" :model="ajaxparams" @keyup.native.enter="$refs.myTable.search()">
        <a-form-model-item label="商品名称">
          <a-input placeholder="请输入商品名称" v-model="ajaxparams['goods_name_like']" />
        </a-form-model-item>
        <a-form-model-item>
          <a-button icon="search" @click="$refs.myTable.search()" type="primary">搜索</a-button>
        </a-form-model-item>
      </a-form-model>
      <!-- 按钮 -->
      <a-space>
        <a-button type="primary" @click="dialogClick('add')">添加</a-button>
      </a-space>
      <!-- 表格 -->
      <MyTable :isSelection="false" class="mt10" :apiUrl="goods_index" :params="ajaxparams" ref="myTable"
        :columns="columns">
        <a-space slot="button" slot-scope="item,row,index" v-if="!row.is_shelves">
          <a-button size="small" type="primary" @click="dialogClick('edit', row)">编辑</a-button>
          <a-popconfirm title="确定是否删除?" ok-text="确定" cancel-text="取消" @confirm="delConfirm(row)">
            <a-button size="small" type="danger">删除</a-button>
          </a-popconfirm>
        </a-space>
        <a-space slot="sort" slot-scope="item,row,index">
          <editable-cell :ref="'edittableCell' + row.id" :text="row.sort" @change="onCellChange($event, row, index)" />
        </a-space>
        <a-space slot="cover_img" slot-scope="item,row,index">
          <img v-if="row.cover_img" style="width:30px;heigth:30px" :src="IMGHOST + '/' + row.cover_img" />
          <span v-else>-</span>
        </a-space>
        <a-space slot="goods_price" slot-scope="item,row,index">
          <span>{{ row.goods_price / 100 }}</span>
        </a-space>
        <a-space slot="goods_vip_price" slot-scope="item,row,index">
          <span>{{ row.goods_vip_price / 100 }}</span>
        </a-space>

        <a-space slot="is_shelves" slot-scope="item,row,index">
          <MySwitch :id="row.id" :index="index" :status="row.is_shelves" checkedText="上架" unCheckedText="下架"
            :isDisabledSwitch="isDisabledSwitch" @switchClick="switchClick"></MySwitch>
        </a-space>
      </MyTable>
    </div>
    <!-- 添加编辑商品 -->
    <!-- <a-modal :maskClosable="false" v-model="dialogVisible" width="82%" :title="dialogType == 'add' ? '添加商品' : '编辑商品'"
      :confirm-loading="submitLoading" @ok="onSubmit" @cancel="handleCancel">
      <a-form-model :label-col="{ span: 3 }" :wrapper-col="{ span: 18 }" :rules="rules" ref="formModel" size="small"
        :model="form"></a-form-model>
    </a-modal> -->
    <ModelForm width="82%" :visible="dialogVisible" :form="form" :rules="rules"
      :title="dialogType == 'add' ? '添加商品' : '编辑商品'" :formItems="formItems" :submitLoading="submitLoading"
      @onSubmit="onSubmit" @handleCancel="handleCancel"></ModelForm>
  </div>
</template>
<script>
import EditableCell from "@/components/editableCell.vue"
import MyTable from "@/components/myTable.vue"
import MySwitch from "@/components/MySwitch/MySwitch.vue"
import ModelForm from "@/components/ModelForm/ModelForm.vue"

import {
  goods_index,
  goods_add,
  goods_edit,
  goods_del,
  goods_changeShelves,
  goods_changeSort,
  goods_detail,
} from "@/api/goods"
import { classify_index } from "@/api/classify"
import { spec_index } from "@/api/spec"
import { setTimeout } from "timers"
export default {
  components: { MyTable, EditableCell, MySwitch, ModelForm },
  data() {
    return {
      goods_index: goods_index,
      IMGHOST: window.IMGHOST,
      ajaxparams: {}, //请求参数
      columns: [
        {
          title: "操作",
          key: "operation",
          fixed: "left",
          width: 150,
          ellipsis: true,
          scopedSlots: { customRender: "button" },
        },
        {
          title: "ID",
          fixed: "left",
          ellipsis: true,
          width: 70,
          dataIndex: "id",
          key: "id",
        },
        {
          title: "商品名称",
          fixed: "left",
          ellipsis: true,
          width: 200,
          dataIndex: "goods_name",
          key: "goods_name",
        },
        {
          title: "商品封面",
          dataIndex: "cover_img",
          key: "cover_img",
          width: 100,
          scopedSlots: { customRender: "cover_img" },
        },
        { title: "商品描述", width: 250, dataIndex: "brief", key: "brief" },
        {
          title: "商品价格",
          ellipsis: true,
          width: 150,
          dataIndex: "goods_price",
          key: "goods_price",
          scopedSlots: { customRender: "goods_price" },
        },
        {
          title: "vip商品价格",
          ellipsis: true,
          width: 150,
          dataIndex: "goods_vip_price",
          key: "goods_vip_price",
          scopedSlots: { customRender: "goods_vip_price" },
        },
        {
          title: "库存",
          ellipsis: true,
          dataIndex: "total_stock",
          key: "total_stock",
          width: 150,
          scopedSlots: { customRender: "total_stock" },
        },

        {
          title: "排序",
          ellipsis: true,
          dataIndex: "sort",
          key: "sort",
          width: 150,
          scopedSlots: { customRender: "sort" },
        },
        {
          title: "上架状态",
          ellipsis: true,
          dataIndex: "is_shelves",
          key: "is_shelves",
          width: 150,
          scopedSlots: { customRender: "is_shelves" },
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "ctime",
          key: "ctime",
          width: 220,
        },
        {
          title: "更新时间",
          ellipsis: true,
          dataIndex: "utime",
          key: "utime",
          width: 220,
        },
      ],
      isDisabledSwitch: false,
      submitLoading: false,
      dialogType: "add",
      dialogVisible: false,
      form: {
      },
      formItems: [],
      classData: []
    }
  },
  mounted() {
    this.getClassData()
  },
  methods: {
    province_code(codes) {
      codes = codes.split(",")
      let _this = this
      let names = []
      for (let i = 0; i < codes.length; i++) {
        let result = _this.regionData.find((item) => {
          return codes[i] == item.code
        })
        if (result) {
          names.push(result.name)
        }
      }
      return names
    },

    // 获取商品分类
    getClassData() {
      classify_index({ page: 1, limit: 999 }).then((res) => {
        this.classData = res
      })
    },
    getSpec() { },

    async onCellChange(e, row, index) {
      //编辑排序
      if (!e || e == 0) {
        this.$refs["edittableCell" + row.id].resetEdit(row.sort)
        this.$message.warning("排序值必须大于0")
        return
      }
      goods_changeSort({ id: row.id, sort: e })
        .then(() => {
          this.$message.success("操作成功")
        })
        .catch(() => {
          this.$refs["edittableCell" + row.id].resetEdit(row.sort)
        })
    },

    //上下架
    async switchClick(params) {
      let { checked, id, index } = params
      this.isDisabledSwitch = true
      goods_changeShelves({ is_shelves: checked, ids: id })
        .then((result) => {
          this.$message.success("操作成功")
          this.$refs.myTable.tableData[index].is_shelves = checked ? 1 : 0
          setTimeout(() => {
            this.isDisabledSwitch = false
          }, 1500)
        })
        .catch(() => {
          this.isDisabledSwitch = false
        })
    },

    handleCancel(type) {
      this.submitLoading = false
      this.dialogVisible = false
      if (this.dialogType == "add") {
        if (type == "add") {
          this.$db.del("addGoodsData")
        } else {
          this.$db.set("addGoodsData", this.form)
        }
      }
    },

    async onSubmit(form) {
      let params = JSON.parse(JSON.stringify(form))
      params.banner_imgs = params.banner_imgs.join(",")
      let spec = params.spec
      if (spec && spec.length > 0) {
        spec.map(item => {
          item.price = item.price * 100
          item.vip_price = item.vip_price * 100
        })
      }

      this.submitLoading = true

      if (this.dialogType == "add") {
        goods_add(params)
          .then((result) => {
            this.$message.success("操作成功")
            setTimeout(() => {
              this.$refs.myTable.getList()
              this.handleCancel("add")
            }, 1500)
          })
          .catch(() => {
            this.submitLoading = false
          })
      } else {
        goods_edit(params)
          .then((result) => {
            this.$message.success("操作成功")
            setTimeout(() => {
              this.$refs.myTable.getList()
              this.handleCancel()
            }, 1500)
          })
          .catch(() => {
            this.submitLoading = false
          })
      }
    },
    async delConfirm(val) {
      goods_del({ id: val.id }).then((result) => {
        this.$message.success("操作成功")
        setTimeout(() => {
          this.$refs.myTable.getList()
        }, 1500)
      })
    },
    dialogClick(type, row) {
      this.dialogType = type
      this.formItems = [{
        label: "商品名称",
        field: "goods_name",
      }, {
        label: "商品标签,逗号隔开",
        field: "tags",
      }, {
        label: "商品描述",
        field: "brief",
        type: "textarea"
      }, {
        label: "轮播图",
        field: "banner_imgs",
        type: "img",
        uploadType: "img",
        tips: "建议上传尺寸：750*750",
        maxLength: 10
      }, {
        label: "封面图",
        field: "cover_img",
        type: "img",
        uploadType: "img",
        tips: "建议上传尺寸：750*750",
        maxLength: 1
      }, {
        label: "视频",
        field: "video",
        type: "img",
        uploadType: "video",
        tips: "建议上传尺寸：750*750",
        maxLength: 1
      }, {
        label: "视频封面",
        field: "video_img",
        type: "img",
        uploadType: "img",
        tips: "建议上传尺寸：750*750。(有设置用设置的封面图，没设置用视频截图)",
        maxLength: 1
      }, {
        label: "分类",
        field: "classify_id",
        type: "select",
        data: this.classData,
        value: 'id',
        name: 'name'
      }, {
        label: "排序",
        field: "sort",
      }, {
        label: "详情描述",
        field: "detail",
        type: "html"
      }, {
        label: "规格",
        type: "spec",
        field: "spec",
        specTpl: {
          img: "",
          desc: "",
          price: 0,
          vip_price: 0,
          stock: 1,
        },
        specColumns: [
          {
            title: "规格图片",
            dataIndex: "img",
            key: "img",
            scopedSlots: { customRender: "img" },
            type: "img",
          },
          {
            title: "商品描述",
            dataIndex: "desc",
            key: "desc",
            scopedSlots: { customRender: "desc" },
          },
          {
            title: "价格",
            dataIndex: "price",
            key: "price",
            scopedSlots: { customRender: "price" },
          },
          {
            title: "vip价格",
            dataIndex: "vip_price",
            key: "vip_price",
            scopedSlots: { customRender: "vip_price" },
          },
          {
            title: "库存",
            dataIndex: "stock",
            key: "stock",
            scopedSlots: { customRender: "stock" },
          },
          {
            title: "操作",
            key: "operation",
            width: 80,
            scopedSlots: { customRender: "button" },
            type: 'button'
          },
        ]
      }]
      if (type == "edit") {
        // row.banner_imgs = row.banner_imgs.split(",")
        // this.form = row
        goods_detail({ id: row.id }).then((res) => {
          let spec = res.spec
          if (spec && spec.length > 0) {
            spec.map(item => {
              item.price = item.price / 100
              item.vip_price = item.vip_price / 100
            })
          }
          res.banner_imgs = res.banner_imgs.split(",")
          this.form = res
        })
      } else {
        // add
        let formData = {
          goods_name: "",
          brief: "",
          tag: "",
          banner_imgs: [],
          cover_img: "",
          video: "",
          sort: 100,
          detail: "",
          spec: [],
        }
        this.form = formData

      }
      this.getSpec()
      this.dialogVisible = true
    },
  },
}
</script>
