<template>
  <div>
    <div style="background-color: #fff;padding: 16px;margin-bottom: 20px;">
      <!-- 搜索条件 -->
      <a-form-model size="small" layout="inline" :model="ajaxparams">
        <a-form-model-item label="广告名称">
          <a-input placeholder="请输入广告名称" v-model="ajaxparams['ad_name_like']" />
        </a-form-model-item>
        <a-form-model-item label="广告位">
          <a-select
            allowClear
            class="width150"
            v-model="ajaxparams['position']"
            placeholder="请选择广告位"
          >
            <a-select-option
              :value="item.code"
              v-for="(item,index) in positionData"
              :key="index"
            >{{item.txt}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-button icon="search" @click="$refs.myTable.getList()" type="primary">搜索</a-button>
        </a-form-model-item>
      </a-form-model>
      <a-button type="primary" @click="dialogClick('add')">添加</a-button>
      <MyTable
        class="mt10"
        :apiUrl="ad_index"
        :params="ajaxparams"
        ref="myTable"
        :columns="columns"
      >
        <a-space slot="button" slot-scope="item,row,index">
          <a-button size="small" type="primary" @click="dialogClick('edit',row)">编辑</a-button>
          <a-popconfirm title="确定是否删除?" ok-text="确定" cancel-text="取消" @confirm="delConfirm(row)">
            <a-button size="small" type="danger">删除</a-button>
          </a-popconfirm>
        </a-space>
        <a-space slot="img" slot-scope="item,row,index">
          <img v-if="row.img" style="width:30px;heigth:30px" :src="IMGHOST+'/'+row.img" />
          <span v-else>-</span>
        </a-space>
        <a-space slot="is_lock" slot-scope="item,row,index">
          <a-switch
            @click="switchClick(row,index)"
            :disabled="isDisabledSwitch"
            checked-children="启用"
            un-checked-children="禁用"
            :defaultChecked="row.is_lock==1?true:false"
          />
        </a-space>
      </MyTable>
    </div>
    <a-modal
      :maskClosable="false"
      v-model="dialogVisible"
      :title="dialogType=='add'?'添加广告':'编辑广告'"
      :confirm-loading="submitLoading"
      @ok="onSubmit"
      @cancel="handleCancel"
    >
      <a-form-model :rules="rules" ref="formModel" size="small" :model="form">
        <a-form-model-item label="广告名" prop="ad_name">
          <a-input placeholder="请输入广告名" v-model="form.ad_name" />
        </a-form-model-item>
        <a-form-model-item label="目标地址">
          <a-input placeholder="请输入目标地址" v-model="form.target" />
        </a-form-model-item>
        <a-form-model-item label="排序">
          <a-input placeholder="请输入排序" v-model="form.sort" />
        </a-form-model-item>
        <a-form-model-item label="广告位" prop="position">
          <a-select v-model="form.position" placeholder="请选择广告位">
            <a-select-option
              :value="item.code"
              v-for="(item,index) in positionData"
              :key="index"
            >{{item.txt}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="广告图" prop="img">
          <div class="fs12 color666">建议上传尺寸：690*300</div>
          <imgUpload
            @remove="uploadRemove"
            :disabled="false"
            ref="logo"
            v-if="dialogVisible"
            :list="form.img?[form.img]:[]"
            :maxLength="1"
            :type="'img'"
            @result="uploadResult"
          ></imgUpload>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import imgUpload from "@/components/myUpload.vue"
import MyTable from "@/components/myTable.vue"

import { ad_index, ad_add, ad_edit, ad_del, ad_lock } from "@/api/ad"
import { config_index } from "@/api/config"

export default {
  components: { MyTable, imgUpload },
  data() {
    return {
      ad_index: ad_index,
      IMGHOST: window.IMGHOST,
      ajaxparams: {}, //请求参数
      /*
			列表header
		*/
      columns: [
        {
          title: "操作",
          key: "operation",
          fixed: "left",
          width: 150,
          ellipsis: true,
          scopedSlots: { customRender: "button" },
        },
        {
          title: "广告位",
          ellipsis: true,
          width: 150,
          dataIndex: "position_name",
          key: "position_name",
        },
        {
          title: "广告名",
          ellipsis: true,
          width: 150,
          dataIndex: "ad_name",
          key: "ad_name",
        },
        {
          title: "广告图",
          ellipsis: true,
          dataIndex: "img",
          key: "img",
          width: 100,
          scopedSlots: { customRender: "img" },
        },
        {
          title: "目标地址",
          ellipsis: true,
          dataIndex: "target",
          key: "target",
          width: 150,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "create_at",
          key: "create_at",
          width: 220,
        },
        {
          title: "更新时间",
          ellipsis: true,
          dataIndex: "update_at",
          key: "update_at",
          width: 220,
        },
      ],
      isDisabledSwitch: false, //是否禁用Switch
      submitLoading: false,
      dialogType: "add",
      dialogVisible: false,
      form: {
        id: 0,
        position: "",
        ad_name: "",
        img: "",
        sort: "",
        target: "",
      },
      positionData: [],
      loading: false,
      tableData: [],
      rules: {
        position: [
          { required: true, message: "请选择广告位", trigger: "blur" },
        ],
        ad_name: [
          { required: true, message: "请输入广告名称", trigger: "blur" },
        ],
        sort: [{ required: true, message: "请输入排序", trigger: "blur" }],
        target: [
          { required: true, message: "请输入目标地址", trigger: "blur" },
        ],
        img: [{ required: true, message: "请选择图片", trigger: "change" }],
      },
    }
  },
  created() {
    this.getpPosition()
  },
  methods: {
    uploadRemove() {
      this.form.img = ""
    },
    // 上传图片回调
    uploadResult(row) {
      //logo
      if (row.type == "img") {
        this.form.img = row.url
      }
    },
    getpPosition() {
      config_index({ option: "sys_ad_position" }).then((result) => {
        this.positionData = result.sys_ad_position
      })
    },
    async switchClick(val, index) {
      //禁用/启用
      this.isDisabledSwitch = true
      ad_lock({ id: val.id })
        .then((result) => {
          this.$message.success("操作成功")
          this.$refs.myTable.tableData[index].status = val ? 1 : 0
          setTimeout(() => {
            this.isDisabledSwitch = false
          }, 1500)
        })
        .catch(() => {
          this.isDisabledSwitch = false
        })
    },
    onSelectChange(val) {
      console.log(val)
    },
    handleCancel() {
      this.submitLoading = false
      this.dialogVisible = false
    },
    async onSubmit() {
      this.$refs.formModel.validate(async (valid) => {
        if (valid) {
          this.submitLoading = true
          if (this.dialogType == "add") {
            ad_add(this.form)
              .then((result) => {
                this.$message.success("操作成功")
                setTimeout(() => {
                  this.$refs.myTable.getList()
                  this.submitLoading = false
                  this.dialogVisible = false
                }, 1500)
              })
              .catch(() => {
                this.submitLoading = false
              })
          } else {
            ad_edit(this.form)
              .then((result) => {
                this.$message.success("操作成功")
                setTimeout(() => {
                  this.$refs.myTable.getList()
                  this.submitLoading = false
                  this.dialogVisible = false
                }, 1500)
              })
              .catch(() => {
                this.submitLoading = false
              })
          }
        } else {
          return false
        }
      })
    },
    async delConfirm(val) {
      //删除
      ad_del({ id: val.id }).then((result) => {
        this.$message.success("操作成功")
        setTimeout(() => {
          this.$refs.myTable.getList()
        }, 1500)
      })
    },
    dialogClick(type, row) {
      this.dialogType = type
      if (type == "edit") {
        this.form = {
          id: row.id,
          position: row.position,
          ad_name: row.ad_name,
          img: row.img,
          sort: row.sort,
          target: row.target,
        }
      } else {
        if (this.positionData.length == 0) {
          this.$message.warning("请创建广告位")
          return
        }
        this.form = {
          position: this.positionData[0].code,
          ad_name: "",
          img: "",
          sort: "",
          target: "",
        }
      }
      this.dialogVisible = true
    },
  },
}
</script>

<style scoped>
.width150 {
  width: 150px;
}
</style>
