import request from '@/utils/request'

// 用户列表
export function users_index(data) {
  return request({
    url: '/merchant/users/index',
    method: 'get',
    params: data
  })
}
// 更改用户状态
export function users_status(data) {
  return request({
    url: '/merchant/users/able',
    method: 'post',
    data
  })
}

// 用户申请列表
export function userApply_index(data) {
  return request({
    url: '/merchant/userApply/index',
    method: 'get',
    params: data
  })
}
// 用户申请拒绝
export function userApply_rejection(data) {
  return request({
    url: '/merchant/userApply/rejection',
    method: 'post',
    data: data
  })
}
// 用户申请通过
export function userApply_approved(data) {
  return request({
    url: '/merchant/userApply/approved',
    method: 'post',
    data: data
  })
}
// 用户地址更改
export function users_changeAddress(data) {
  return request({
    url: '/merchant/users/changeAddress',
    method: 'post',
    data: data
  })
}

// 用户地址
export function users_getAddress(data) {
  return request({
    url: '/merchant/users/getAddress',
    method: 'get',
    params: data
  })
}
// 更新会员等级
export function users_level(data) {
  return request({
    url: '/merchant/users/level',
    method: 'post',
    data: data
  })
}
// 更新股东等级
export function users_bonus_level(data) {
  return request({
    url: '/merchant/users/bonusLevel',
    method: 'post',
    data: data
  })
}
// 旅游报名列表
export function userTravelRegistration_index(data) {
  return request({
    url: '/merchant/userTravelRegistration/index',
    method: 'get',
    params: data
  })
}
// 修改旅游报名状态
export function userTravelRegistration_changeState(data) {
  return request({
    url: '/merchant/userTravelRegistration/changeState',
    method: 'post',
    data: data
  })
}
// 福利礼包发放列表
export function userWelfarePackage_index(data) {
  return request({
    url: '/merchant/userWelfarePackage/index',
    method: 'get',
    params: data
  })
}
// 批量处理福利礼包
export function userWelfarePackage_batchOpt(data) {
  return request({
    url: '/merchant/userWelfarePackage/batchOpt',
    method: 'post',
    data: data
  })
}
// 鉴权
export function users_authCode(data) {
  return request({
    url: '/merchant/users/authCode',
    method: 'post',
    data: data
  })
}






