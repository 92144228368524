<template>
	<div class="editable-cell">
		<div v-if="editable" class="editable-cell-input-wrapper">
		  <a-input @blur="blur" :disabled="isDisabled" :value="value" @change="handleChange" @pressEnter="check" /><a-icon
			type="check"
			class="editable-cell-icon-check"
			@click="check"
		  />
		</div>
		<div v-else class="editable-cell-text-wrapper colorlue">
		  {{ value || ' ' }}
		  <a-icon type="edit" class="editable-cell-icon" @click="edit" />
		</div>
	</div>
</template>
<script>
export default {
  name: 'EditableCell',
  props: {
	  text: [String,Number],
	  isDisabled:{
		  type:[String,Boolean],
		  default:false
	  }
  },
  data() {
      return {
        value: this.text,
		editable: false,
      };
  },
  created() {
   	let tableData = [];
  },
  methods:{
	   handleChange(e) {
		 const value = e.target.value;
		 this.value = value;
	   },
	   resetEdit(val){
			this.value = val
	   },
	   blur(){
		   setTimeout(()=>{
			  if(this.editable){
			    this.value = this.text
				this.editable = false;
			  }
		   },500)
	   },
	   check() {
		 this.editable = false;
		 this.$emit('change', this.value);
	   },
	   edit() {
		 this.editable = true;
	   },
  }
}
</script>

<style scoped>
	.editable-cell {
	  position: relative;
	}
	
	.editable-cell-input-wrapper,
	.editable-cell-text-wrapper {
	  padding-right: 24px;
	}
	
	.editable-cell-text-wrapper {
	  padding: 5px 24px 5px 5px;
	}
	
	.editable-cell-icon,
	.editable-cell-icon-check {
	  position: absolute;
	  right: 0;
	  width: 20px;
	  cursor: pointer;
	}
	
	.editable-cell-icon {
	  line-height: 24px;
	  display: none;
	}
	
	.editable-cell-icon-check {
	  line-height: 35px;
	}
	
	.editable-cell:hover .editable-cell-icon {
	  display: inline-block;
	}
	
	.editable-cell-icon:hover,
	.editable-cell-icon-check:hover {
	  color: #108ee9;
	}
	
	.editable-add-btn {
	  margin-bottom: 8px;
	}
	.colorlue{
		color: #108ee9
	}
</style>
