<style scoped>
  .img {
    height: 30px;
  }

  .ellipsis-1 {
    max-width: 180px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
    word-break: break-all;
  }

  .w150 {
    width: 150px;
  }
</style>
<template>
  <div class="pageCentent">
    <!-- 搜索条件 -->
    <a-form-model size="small" layout="inline" :model="ajaxparams" @keyup.native.enter="submitForm()">
      <a-form-model-item label="金额">
        <a-input type="number" placeholder="请输入金额" v-model="formModel['1_value']" />
      </a-form-model-item>
      <a-form-model-item label="等级">
        <a-select allowClear class="w150" v-model="formModel['2_level']" placeholder="请选择等级"  :showSearch="true" optionFilterProp="label">
          <a-select-option :label="item.vip" :value="item.key" v-for="(item, index) in levels" :key="index">{{ item.vip }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="共富币">
        <a-select allowClear class="w150" v-model="formModel['is_coin']" placeholder="请选择等级"  :showSearch="true" optionFilterProp="label">
          <a-select-option label="是" value="1">是</a-select-option>
          <a-select-option label="否" value="0">否</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="省份">
        <a-select style="width:200px" allowClear v-model="formModel['2_province_code']" placeholder="请选择省份" :showSearch="true" optionFilterProp="label">
          <a-select-option :label="item.name" :value="item.code" v-for="(item, index) in provinceData" :key="index">{{ item.name
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="日期">
        <a-date-picker v-model="formModel['1_create_at_between']" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" value=null />
      </a-form-model-item>
      <a-form-model-item>
        <a-button icon="search" @click="submitForm()" type="primary">搜索</a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <MyTable :isSelection="false" class="mt10" :apiUrl="apiUrl" :params="ajaxparams" ref="myTable" :columns="columns">
        <a-space slot="balance" slot-scope="item,row,index">
          <span>{{ (item / 100).toFixed(2) }}</span>
        </a-space>
        <a-space slot="value" slot-scope="item,row,index">
          <span>{{ (item / 100).toFixed(2) }}</span>
        </a-space>
        <a-space slot="is_coin" slot-scope="item,row,index">
          <span>{{ item ? "是" : "否"}}</span>
        </a-space>

		<a-space slot="is_salesman" slot-scope="item,row,index">
          <span>{{ item ? "是" : "否"}}</span>
        </a-space>

		<a-space slot="bonus_level" slot-scope="item,row,index">
          <span>{{ item ? (item * 10 + "万合伙人") : "非合伙人"}}</span>
        </a-space>

        <a-space slot="province_code" slot-scope="item,row,index">
          <span>{{ findProvinceName(item) }}</span>
        </a-space>
        <a-space slot="level" slot-scope="item,row,index">
          <a-tag :color="t.color" v-for="(t, i) in levels" :key="i" v-if="row.level == t.key">{{ row.is_salesman ? "业务员" :
            t.vip }}</a-tag>
        </a-space>
      </MyTable>
    </div>
  </div>
</template>
<script>
import MyTable from "@/components/myTable.vue"
import {
  user_bill_index,
} from "@/api/user_bill"
import moment from "moment"

export default {
  components: { MyTable },
  data() {
    return {
      apiUrl: user_bill_index,
      ajaxparams: {},
      formModel:{},
      levels: [
        { vip: "vip", key: "vip", color: "#87d068" },
        { vip: "A1", key: "A1", color: "#2db7f5" },
        { vip: "A2", key: "A2", color: "#ff4500" },
        { vip: "A3", key: "A3", color: "#f50" },
        { vip: "无会员", key: "", color: "#909399" },
        { vip: "业务员", key: "A4", color: "#709399" },
      ],

      columns: [
        {
          title: "id",
          ellipsis: true,
          dataIndex: "id",
          key: "id",
        },
        {
          title: "用户名",
          ellipsis: true,
          width:120,
          dataIndex: "name",
          key: "name",
        },
         {
          title: "省份",
          dataIndex: "province_code",
          key: "province_code",
          scopedSlots: { customRender: "province_code" },
        },
        {
          title: "等级",
          width:80,
          dataIndex: "level",
          key: "level",
          scopedSlots: { customRender: "level" },
        },
        {
          title: "金额",
          ellipsis: true,
          dataIndex: "value",
          key: "value",
          scopedSlots: { customRender: "value" },
        },
         {
          title: "余额",
          ellipsis: true,
          dataIndex: "balance",
          key: "balance",
          scopedSlots: { customRender: "balance" },
        },
        {
          title: "是否共富币",
          dataIndex: "is_coin",
          width:80,
          key: "is_coin",
          scopedSlots: { customRender: "is_coin" },
        },
		{
          title: "业务员",
          dataIndex: "is_salesman",
          width:80,
          key: "is_salesman",
          scopedSlots: { customRender: "is_salesman" },
        },
		{
          title: "内部合伙人",
          dataIndex: "bonus_level",
          width:80,
          key: "bonus_level",
          scopedSlots: { customRender: "bonus_level" },
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
          key: "remark",
        },
        {
          title: "日期",
          dataIndex: "create_at",
          key: "create_at",
        },
      ],
      regionData: [],
	  provinceData:[],
    }
  },
  computed: {
   
  },
  created() {
  },
  mounted() {
    this.regionData = this.$db.get("regionList").data
    this.provinceData = this.regionData.filter(item => {
      return item.depth == 1
    })

	console.log(this.provinceData)
  },
  methods: {
    findProvinceName(code){
			let rest = this.regionData.find((item)=>{return code==item.code})
			if(rest){
				return rest.name
			}
			return ''
		},
    submitForm(){
      this.ajaxparams = {}
      for (let k in this.formModel){
          if(k === '1_value')this.ajaxparams[k] = this.formModel[k] * 100
          else if(k === '2_level' && this.formModel[k]  === 'A4') this.ajaxparams["is_salesman"] = 1
          else if(k === '1_create_at_between') this.ajaxparams[k] = this.formModel[k] + " 00:00:00 - " + this.formModel[k] + " 23:59:59"
          else this.ajaxparams[k] = this.formModel[k]
      }
      this.$nextTick(() => {
        this.$refs.myTable.search()
      })
      
    }
  },
}
</script>
	