<!-- 商品特性 -->
<style scoped>
.slot-characts {
  overflow: auto;
}
</style>
<template>
  <div>
    <div style="background-color: #fff;padding: 16px;margin-bottom: 20px;">
      <MyTable
        class="mt10"
        :apiUrl="charact_index"
        :params="ajaxparams"
        ref="myTable"
        :columns="columns"
        :isPagination="false"
      >
        <a-space slot="button" slot-scope="item,row,index">
          <a-button size="small" type="primary" @click="dialogClick(row)">添加</a-button>
        </a-space>
        <a-space slot="charact_tags" slot-scope="item,row,index" class="slot-characts">
          <a-tag
            color="green"
            closable
            v-for="v in item"
            :key="v.id"
            @close="delTag(v.id)"
          >{{v.name}}</a-tag>
        </a-space>
      </MyTable>
    </div>
    <a-modal
      :maskClosable="false"
      v-model="dialogVisible"
      title="编辑特性"
      :confirm-loading="submitLoading"
      @ok="onSubmit"
      @cancel="handleCancel"
    >
      <a-form-model :rules="rules" ref="formModel" size="small" :model="form">
        <a-form-model-item label="商品分类" placeholder="请选择分类">
          <a-select v-model="form.classify_id" disabled>
            <a-select-option
              :value="v.classify_id"
              v-for="(v,k) of classifyData"
              :key="k"
            >{{v.classify_name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="商品特性" prop="name">
          <a-input placeholder="请输入商品特性" v-model="form.name" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import MyTable from "@/components/myTable.vue"

import { charact_index, charact_add, charact_del } from "@/api/charact"

export default {
  components: { MyTable },
  data() {
    return {
      charact_index: charact_index,
      ajaxparams: {}, //请求参数
      /*
				列表header
			*/
      columns: [
        {
          title: "操作",
          key: "operation",
          width: 100,
          scopedSlots: { customRender: "button" },
        },
        {
          title: "商品分类",
          width: 100,
          ellipsis: true,
          dataIndex: "classify_name",
          key: "classify_name",
        },
        {
          title: "商品特性",
          dataIndex: "characts",
          key: "characts",
          scopedSlots: { customRender: "charact_tags" },
        },
      ],
      submitLoading: false,
      dialogVisible: false,
      form: {
        classify_id: 0,
        name: "",
      },
      loading: false,
      rules: {
        classify_id: [
          { required: true, message: "请选择分类", trigger: "blur" },
        ],
        characts: [
          { required: true, message: "请输入商品特性", trigger: "blur" },
        ],
      },
      classifyData: [],
    }
  },
  created() {
    // this.getClassify()
    setTimeout((res) => {}, 100)
  },
  methods: {
    async delTag(tagId) {
      await charact_del({ id: tagId })
      console.log("del Tag" + tagId)
    },

    handleCancel() {
      this.submitLoading = false
      this.dialogVisible = false
    },
    async onSubmit() {
      this.$refs.formModel.validate(async (valid) => {
        if (valid) {
          this.submitLoading = true
          charact_add(this.form)
            .then((result) => {
              this.$message.success("添加成功")
              setTimeout(() => {
                this.$refs.myTable.getList()
                this.submitLoading = false
                this.dialogVisible = false
              }, 1500)
            })
            .catch(() => {
              this.submitLoading = false
            })
        } else {
          return false
        }
      })
    },
    dialogClick(row) {
      this.form = {
        classify_id: row.classify_id,
        name: "",
      }
      this.dialogVisible = true
      this.classifyData = this.$refs.myTable.tableData
    },
  },
}
</script>
