
<template>
  <div class="pageCentent">

    <MyTable ref="myTable" :isSelection="false" :api="userGiftIndex" :columns="columns" :params="search" class="mt10">
    </MyTable>
  </div>
</template>
<script>
import { user_gift_index } from "@/api/user_gift"
import MyTable from '../../components/MyTable/MyTable.vue'

export default {
  data() {
    return {
      userGiftIndex: user_gift_index,
      columns: [
        {
          width: 100,
          title: 'id',
          dataIndex: 'id',
          key: 'id',
          fixed: "left",
        },
        {
          width: 120,
          title: '用户名',
          dataIndex: 'nickname',
          key: 'nickname',
          fixed: "left",
        },
        {
          width: 120,
          title: '会员过期时间',
          dataIndex: 'expired',
          key: 'expired',
        },
        {
          width: 120,
          title: 'vip礼包已领数量',
          dataIndex: 'vip_take_num',
          key: 'vip_take_num',
          scopedSlots: { customRender: "vip_take_num" },
        },
        {
          width: 120,
          title: 'vip礼包未领数量',
          dataIndex: 'vip_untake_num',
          key: 'vip_untake_num',
          scopedSlots: { customRender: "vip_untake_num" },
        },
        {
          width: 120,
          title: '推荐礼包已领数量',
          dataIndex: 'recommend_take_num',
          key: 'recommend_take_num',
          scopedSlots: { customRender: "recommend_take_num" },
        },
        {
          width: 120,
          title: '推荐礼包未领数量',
          dataIndex: 'recommend_untake_num',
          key: 'recommend_untake_num',
          scopedSlots: { customRender: "recommend_untake_num" },
        },
        {
          title: "创建时间",
          dataIndex: "create_at",
          key: "create_at",
          width: 120,
        },
      ],
      search: {},
    }
  },
  created(){
  },
  components: {
    MyTable
  },
  methods: {
  },
}
</script>
	
<style scoped>
.img {
  /* width:30px; */
  height: 30px;
}

.ellipsis-1 {
  max-width: 180px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  word-break: break-all;
}
</style>