import request from '@/utils/request'

// 评论列表
export function comment_list(data) {
    return request({
        url: '/merchant/GoodsComment/index',
        method: 'get',
        params: data
    })
}

// 更新状态
export function comment_able(data) {
    return request({
        url: '/merchant/GoodsComment/review',
        method: 'post',
        data: data
    })
}
