<!-- 广告管理 -->

<style scoped>
	.img{
		/* width:30px; */
		height:30px
	}
	.ellipsis-1{
		max-width: 180px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden; 
		word-wrap: break-word;
		 word-break: break-all 
	}
	.w150{
		width: 150px;
	}
</style>
<template>
  <div class="pageCentent">
    <div >
		<a-space>
			<a-popconfirm
			title="确定批量处理?"
			ok-text="确定"
			cancel-text="取消"
			@confirm="batchOpt" >
				<a-button size="small" type="primary" >批量处理</a-button>
			</a-popconfirm>
			
		</a-space>
      <MyTable class="mt10" :disableSelection="{key:'state',value:0}" @selectRow="chnageSelectRow" :apiUrl="apiUrl" :params="ajaxparams" ref="myTable" :columns="columns">
		 <a-space slot='button' slot-scope="item,row,index">
			<a-popconfirm
			v-if="row.state==0"
			title="确定已处理?"
			ok-text="确定"
			cancel-text="取消"
			@confirm="approved(row)" >
				<a-button size="small" type="primary" >
					处理
				</a-button>
			</a-popconfirm>
        </a-space>
		<a-space slot='date' slot-scope="item,row,index">
			<span>{{row.date?(row.date.slice(0,4)+'-'+row.date.slice(4)):'-'}}</span>
        </a-space>
        <a-space slot='state' slot-scope="item,row,index">
			<span>{{row.state==0?'未处理':'已处理'}}</span>
        </a-space>
      </MyTable>
    </div>
	<a-modal :footer="null" v-model="dialogVisible" title="查看" >
		<img style="width:100%" :src="dialogImg" alt="">
	</a-modal>
  </div>
</template>
<script>
import MyTable from "@/components/myTable.vue"
import {userWelfarePackage_index,userWelfarePackage_batchOpt} from '@/api/users'
export default {
	components:{MyTable},
  data() {
    return {
		IMGHOST:window.IMGHOST,
		apiUrl:userWelfarePackage_index,
		ajaxparams:{},
		dialogImg:'',
		dialogVisible:false,
		columns:[
			{ title: '用户', ellipsis: true,dataIndex: 'name',fixed: 'left', key: 'name',width: 120,scopedSlots: { customRender: 'name' }},
			{ title: '日期', ellipsis: true, width: 120, dataIndex: 'date', key: 'date' ,scopedSlots: { customRender: 'date' }},
			{ title: '状态', ellipsis: true,dataIndex: 'state', key: 'state',width: 100,scopedSlots: { customRender: 'state' }},
			{ title: '创建时间', ellipsis: true,dataIndex: 'create_at', key: 'create_at',width: 200},
			{ title: '操作', key: 'operation',fixed: 'right', width: 120, scopedSlots: { customRender: 'button' } }
		],
		
		selectRow:[]
	}
  },
  created() {
  },
  mounted() {
  },
  destroyed() {  
  },
  methods: {
	chnageSelectRow(row){
		this.selectRow = row
	},
	showDialog(src){
		this.dialogImg = src
		this.dialogVisible = true
	},
	batchOpt(){
		if(this.selectRow.length==0){
			this.$message.warning('请选择处理数据');
			return 
		}else{
			let ids = []
			for (let i = 0; i < this.selectRow.length; i++) {
				ids.push(this.selectRow[i].id)
			}
			this.approved({id:ids.join(',')})
		}
	},
	approved(row){
		userWelfarePackage_batchOpt({id:row.id}).then((res)=>{
			this.$message.success('操作成功');
			setTimeout(()=>{
				this.confirmLoading = false;
				this.isModalShow = false
				this.$refs.myTable.getList()
			},1500)
		})
	},
  },
}
</script>
	