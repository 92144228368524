<!-- 账号管理 -->
<style scoped></style>
<template>
  <div>
    <div class="table-box">
      <a-button type="primary" @click="dialogClick('add')">添加</a-button>
      <a-table class="mt10" :row-selection="{ onChange: onSelectChange }" :scroll="{ x: '100%', y: 0 }"
        :data-source="tableData" :columns="columns">
        <a-space slot="button" slot-scope="item,row,index">
          <a-button size="small" type="primary" @click="dialogClick('edit', row)">编辑</a-button>
          <a-popconfirm title="确定是否删除?" ok-text="确定" cancel-text="取消" @confirm="delConfirm(row)">
            <a-button size="small" type="danger">删除</a-button>
          </a-popconfirm>
        </a-space>
        <a-space slot="avatar" slot-scope="item,row,index">
          <img v-if="row.avatar" style="width:30px;heigth:30px" :src="IMGHOST + '/' + row.avatar" />
          <span v-else>-</span>
        </a-space>
        <a-space slot="status" slot-scope="item,row,index">
          <MySwitch :id="row.id" :index="index" :status="row.status" checkedText="启用" unCheckedText="禁用"
            :isDisabledSwitch="isDisabledSwitch" @switchClick="switchClick"></MySwitch>
        </a-space>
        <a-space slot="role_id" slot-scope="item,row,index">
          <span v-if="row.role_id == 1">超级管理员</span>
          <span v-else>{{ roleFand(row) }}</span>
        </a-space>
      </a-table>
    </div>

    <ModelForm :visible="dialogVisible" :form="form" :rules="rules" :title="dialogType == 'add' ? '添加账号' : '编辑账号'"
      :formItems="formItems" :submitLoading="submitLoading" @onSubmit="onSubmit" @handleCancel="handleCancel"></ModelForm>
  </div>
</template>
<script>
import {
  admin_index,
  admin_add,
  admin_edit,
  admin_del,
  admin_able,
} from "@/api/admin"
import { role_index } from "@/api/role"
import { removeToken, get, del } from "@/utils/auth"
import MySwitch from "@/components/MySwitch/MySwitch.vue"
import ModelForm from "@/components/ModelForm/ModelForm.vue"

export default {
  data() {
    return {
      admin_index: admin_index,
      IMGHOST: window.IMGHOST,
      apiUrl: "merchant_admin_index", //请求地址
      ajaxparams: {}, //请求参数
      rowSelection: [],
      isDisabledSwitch: false,
      /*
      列表header
    */
      columns: [
        {
          title: "操作",
          key: "operation",
          fixed: "left",
          width: 150,
          ellipsis: true,
          scopedSlots: { customRender: "button" },
        },
        {
          title: "用户名",
          ellipsis: true,
          width: 150,
          dataIndex: "username",
          key: "username",
        },
        {
          title: "手机号码",
          ellipsis: true,
          width: 150,
          dataIndex: "mobile",
          key: "mobile",
        },
        {
          title: "头像",
          ellipsis: true,
          dataIndex: "avatar",
          key: "avatar",
          width: 100,
          scopedSlots: { customRender: "avatar" },
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "status",
          key: "status",
          width: 150,
          scopedSlots: { customRender: "status" },
        },
        {
          title: "角色",
          ellipsis: true,
          dataIndex: "role_id",
          key: "role_id",
          width: 150,
          scopedSlots: { customRender: "role_id" },
        },
        // { title: '最后登录IP', ellipsis: true,dataIndex: 'last_login_ip', key: 'last_login_ip',width: 150},
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "ctime",
          key: "ctime",
          width: 220,
        },
        {
          title: "更新时间",
          ellipsis: true,
          dataIndex: "utime",
          key: "utime",
          width: 220,
        },
      ],
      submitLoading: false,
      dialogType: "add",
      dialogVisible: false,
      form: {
        // id:0,
        username: "",
        password: "",
        role_id: "",
        mobile: "",
      },
      roleData: [],
      loading: false,
      tableData: [],
      formItems: [],
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        mobile: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        role_id: [{ required: true, message: "请选择角色", trigger: "change" }],
      },
    }
  },
  components: {
    MySwitch,
    ModelForm,
  },
  created() {
    this.getList()
    this.merchant_role_index()
  },
  methods: {
    async getList() {
      this.loading = true
      admin_index()
        .then((result) => {
          for (let i = 0; i < result.length; i++) {
            result[i].key = i
          }
          this.tableData = result
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    switchClick(params) {
      let { checked, id } = params
      this.isDisabledSwitch = true
      admin_able({ status: checked, id })
        .then(() => {
          this.$message.success("操作成功")
          setTimeout(() => {
            this.isDisabledSwitch = false
          }, 1000)
        })
        .catch(() => {
          this.isDisabledSwitch = false
        })
    },
    roleFand(row) {
      let res = this.roleData.find((item) => {
        return item.id == row.role_id
      })
      if (res) {
        return res.name
      }
      return "-"
    },

    async merchant_role_index() {
      //获取所有角色
      role_index({ page: 1, limit: 10000 }).then((result) => {
        this.roleData = result
      })
    },
    onSelectChange(val) {
      console.log(val)
    },
    handleCancel() {
      this.submitLoading = false
      this.dialogVisible = false
    },
    async onSubmit(form) {
      this.submitLoading = true
      if (this.dialogType == "add") {
        admin_add(form)
          .then((result) => {
            this.$message.success("操作成功")
            setTimeout(() => {
              this.getList()
              this.submitLoading = false
              this.dialogVisible = false
            }, 1500)
          })
          .catch(() => {
            this.submitLoading = false
          })
      } else {
        let auth_id = get("userInfo").id
        admin_edit(form)
          .then((result) => {
            this.$message.success("操作成功")
            setTimeout(() => {
              this.getList()
              this.submitLoading = false
              this.dialogVisible = false
              if (auth_id == form.id) {
                removeToken()
                del("userInfo")
                location.reload()
              }
            }, 1500)
          })
          .catch(() => {
            this.submitLoading = false
          })
      }
    },
    async delConfirm(val) {
      //删除
      admin_del({ id: val.id }).then((result) => {
        this.$message.success("操作成功")
        setTimeout(() => {
          this.getList()
        }, 1500)
      })
    },
    dialogClick(type, row) {
      this.dialogType = type
      if (type == "edit") {
        this.$nextTick(() => {
          this.form = {
            id: row.id,
            password: "",
            role_id: row.role_id,
            mobile: row.mobile,
          }
          this.formItems = [
            {
              label: "密码",
              field: "password",
              type: "password",
            },
            {
              label: "手机号",
              field: "mobile",
            },
            {
              label: "角色",
              field: "role_id",
              type: "select",
              data: this.roleData,
              value: "id",
              name: 'name'
            },
          ]
        })
      } else {
        if (this.roleData.length == 0) {
          this.$message.warning("请前往创建角色")
          return
        }
        this.$nextTick(() => {
          this.form = {
            password: "",
            role_id: "",
            mobile: "",
            username: "",
          }
        })
        this.formItems = [
          {
            label: "用户名",
            field: "username",
          },
          {
            label: "密码",
            field: "password",
            type: "password",
          },
          {
            label: "手机号",
            field: "mobile",
          },
          {
            label: "角色",
            field: "role_id",
            type: "select",
            data: this.roleData,
            value: "id",
            name: 'name'
          },
        ]
      }
      this.dialogVisible = true
    },
  },
}
</script>
