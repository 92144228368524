
<template>
  <div class="pageCentent">
    <!-- 搜索条件 -->
    <SearchOption :searchItems="searchItems" @getSearch="getSearch"></SearchOption>

    <MyTable ref="myTable" :api="usersIndex" :columns="columns" :params="search" class="mt10">
      <div slot="coupon_num" slot-scope="item,row">
        <a-tag color="#87d068">{{ row.coupon_num }}张</a-tag>
      </div>
      <a-space slot="status" slot-scope="item,row,index">
        <MySwitch :id="row.id" :index="index" :status="row.status" checkedText="正常" unCheckedText="锁定"
          :isDisabledSwitch="isDisabledSwitch" @switchClick="switchClick"></MySwitch>
      </a-space>
      <a-space slot="expired" slot-scope="item,row,index">
        {{item > today ? "是" :"否"}}
      </a-space>
      <a-space slot="recommend_id" slot-scope="item,row,index">
        {{item == 0 ? "-" : item}}
      </a-space>
    </MyTable>
  </div>
</template>
<script>
import { users_index, users_status } from "@/api/users"
import SearchOption from "@/components/SearchOption/SearchOption"
import MySwitch from "@/components/MySwitch/MySwitch.vue"
import MyTable from '../../components/MyTable/MyTable.vue'

export default {
  data() {
    return {
      usersIndex: users_index,
      columns: [
        {
          width: 100,
          title: 'id',
          dataIndex: 'id',
          key: 'id',
          fixed: "left",
        },
        {
          width: 120,
          title: '用户名',
          dataIndex: 'nickname',
          key: 'nickname',
        },
        {
          width: 120,
          title: '手机号',
          dataIndex: 'mobile',
          key: 'mobile',
        },
        {
          width: 120,
          title: '推荐人',
          dataIndex: 'parent_name',
          key: 'parent_name',
        },
        {
          width: 120,
          title: '推荐人id',
          dataIndex: 'recommend_id',
          key: 'recommend_id',
          scopedSlots: { customRender: "recommend_id" },
        },
        {
          width: 110,
          title: '收货人',
          dataIndex: 'consignee_name',
          key: 'consignee_name',
          align: 'center',
        },
        {
          width: 110,
          title: '收货人手机号',
          dataIndex: 'consignee_mobile',
          key: 'consignee_mobile',
          align: 'center',
        },
        {
          width: 200,
          title: '收货地址',
          dataIndex: 'address',
          key: 'address',
          align: 'center',
        },
        {
          width: 60,
          title: '会员',
          dataIndex: 'expired',
          key: 'expired',
          scopedSlots: { customRender: "expired" }
        },
        {
          width: 100,
          title: '积分',
          dataIndex: 'point',
          key: 'point',
        },
        {
          width: 200,
          title: '优惠券数量',
          dataIndex: 'coupon_num',
          key: 'coupon_num',
          align: 'center',
          scopedSlots: { customRender: "coupon_num" },
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "status",
          key: "status",
          width: 120,
          align: 'center',
          scopedSlots: { customRender: "status" },
        },
        {
          width: 100,
          title: '总签到数',
          dataIndex: 'sign_in_total_days',
          key: 'sign_in_total_days',
        },
        {
          title: "连续签到数",
          dataIndex: "sign_in_days",
          key: "sign_in_days",
          width: 110,
        },
        // {
        //   width: 200,
        //   title: '最后一次签到',
        //   dataIndex: 'sign_date',
        //   key: 'sign_date',
        // },
        {
          title: "最近登录时间",
          dataIndex: "update_at",
          key: "update_at",
          width: 120,
        },
        {
          title: "成为会员时间",
          dataIndex: "create_at",
          key: "create_at",
          width: 120,
        },
        // {
        //   title: "操作",
        //   key: "operation",
        //   width: 200,
        //   fixed: "right",
        //   scopedSlots: { customRender: "button" },
        // },
      ],
      searchItems: [
        {
          label: "用户名",
          field: "nickname_like",
        },
        {
          label: "是否VIP",
          field: "is_vip_no",
          type: "select",
          data: [
            {
              id: 1,
              no: '0',
              name: "普通",
            },
            {
              id: 2,
              no: '1',
              name: "VIP",
            },
          ],
          value: "no",
          name: "name",
        },
        {
          label: "成为会员时间",
          type: "date",
          field: "expired_no",
        },
        {
          label: "收货人",
          field: "consignee_name_no",
        },
        {
          label: "手机号",
          field: "consignee_mobile_no",
        },
        {
          label: "推荐人",
          field: "recommend_name_no",
        },
        {
          label: "推荐人ID",
          field: "recommend_id",
        },
      ],
      search: {},
      isDisabledSwitch: false,
      today:'',
    }
  },
  created(){
    let ts = new Date().getTime()
    this.today = this.$common.timeToDate(ts,true)
  },
  components: {
    SearchOption,
    MySwitch,
    MyTable
  },
  methods: {
    
    getSearch(form) {
      this.search = form
      this.$nextTick(() => {
        this.$refs.myTable.search()
      })
    },
    switchClick(params) {
      let { checked, id } = params
      this.isDisabledSwitch = true
      users_status({
        id
      })
        .then(() => {
          this.$message.success("操作成功")
          setTimeout(() => {
            this.isDisabledSwitch = false
          }, 1000)
        })
        .catch(() => {
          this.isDisabledSwitch = false
        })
    },
  },
}
</script>
	
<style scoped>
.img {
  /* width:30px; */
  height: 30px;
}

.ellipsis-1 {
  max-width: 180px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  word-break: break-all;
}
</style>