<!-- 角色管理 -->
<template>
  <div>
    <div class="table-box">
      <a-button type="primary" @click="dialogClick('add')">添加</a-button>
      <a-table
        class="mt10"
        :row-selection="{onChange:onSelectChange}"
        :scroll="{ x: '100%', y: 0 }"
        :data-source="tableData"
        :columns="columns"
      >
        <!-- <MyTable @selectRow="tableSelectRow" class="mt10" :apiUrl="apiUrl" :params="ajaxparams" ref="myTable" :columns="columns"> -->
        <a-space slot="button" slot-scope="item,row,index">
          <a-button size="small" type="primary" @click="dialogClick('edit',row)">编辑</a-button>
          <a-popconfirm title="确定是否删除?" ok-text="确定" cancel-text="取消" @confirm="delConfirm(row)">
            <a-button size="small" type="danger">删除</a-button>
          </a-popconfirm>
          <a-button size="small" type="dashed" @click="dialogClick('menu',row)">配置权限</a-button>
        </a-space>
      </a-table>
    </div>
    <ModelForm
      :visible="dialogVisible"
      :form="form"
      :rules="rules"
      :title="dialogType=='add'?'添加':dialogType=='edit'?'编辑':'配置权限'"
      :formItems="formItems"
      :submitLoading="submitLoading"
      @onSubmit="onSubmit"
      @handleCancel="handleCancel"
    ></ModelForm>
  </div>
</template>
<script>
import {
  role_index,
  role_add,
  role_edit,
  role_del,
  role_permission,
} from "@/api/role"
import { config_menus } from "@/api/config"
import ModelForm from "@/components/ModelForm/ModelForm.vue"

export default {
  // components:{MyTable},
  data() {
    return {
      columns: [
        {
          title: "操作",
          key: "operation",
          fixed: "left",
          width: 210,
          ellipsis: true,
          scopedSlots: { customRender: "button" },
        },
        {
          title: "角色名称",
          ellipsis: true,
          width: 100,
          dataIndex: "name",
          key: "name",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "ctime",
          key: "ctime",
          width: 220,
        },
        {
          title: "更新时间",
          ellipsis: true,
          dataIndex: "utime",
          key: "utime",
          width: 220,
        },
      ],
      submitLoading: false,
      dialogType: "add",
      dialogVisible: false,
      formItems: [],
      form: {},
      all_menu: [],
      replaceFields: {
        key: "id",
        title: "name",
      },
      tableData: [],
      loading: false,
      rules: {
        name: [{ required: true, message: "请输入用户名称", trigger: "blur" }],
      },
    }
  },
  components: {
    ModelForm,
  },
  mounted() {
    this.getList()
    this.merchant_menu_index()
  },
  methods: {
    onSelectChange(val) {
      console.log(val)
    },
    async getList() {
      this.loading = true
      role_index()
        .then((result) => {
          for (let i = 0; i < result.length; i++) {
            result[i].key = i
          }
          this.tableData = result
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    async onSubmit(form) {
      this.submitLoading = true

      let model = Object.assign({}, form)
      let res = null
      if (this.dialogType == "add") {
        if (!model.name) {
          this.$message.warning("请输入角色名称")
          return
        }
        role_add(model)
          .then((result) => {
            this.$message.success("操作成功")
            setTimeout(() => {
              this.getList()
              this.submitLoading = false
              this.dialogVisible = false
            }, 1500)
          })
          .catch(() => {
            this.submitLoading = false
          })
        // res = await this.$api.merchant_role_add(model)
      } else if (this.dialogType == "edit") {
        if (!model.name) {
          this.$message.warning("请输入角色名称")
          return
        }
        role_edit(model)
          .then((result) => {
            this.$message.success("操作成功")
            setTimeout(() => {
              this.getList()
              this.submitLoading = false
              this.dialogVisible = false
            }, 1500)
          })
          .catch(() => {
            this.submitLoading = false
          })
      } else {
        let menus = this.form.menus
          ? JSON.parse(JSON.stringify(this.form.menus))
          : []
        if (menus.length > 0) {
          for (let i = 0; i < this.all_menu.length; i++) {
            let data = menus.find((item) => {
              return item == this.all_menu[i].id
            })
            if (data) {
              continue
            }
            for (let j = 0; j < this.all_menu[i].children.length; j++) {
              let data2 = menus.find((item) => {
                return item == this.all_menu[i].children[j].id
              })
              if (data2) {
                menus.push(this.all_menu[i].id)
                break
              }
            }
          }
        }
        if (menus.length == 0) {
          this.$message.warning("请请选择权限")
          this.submitLoading = false
          return
        }
        model.menus = menus.join(",")
        role_permission(model)
          .then((result) => {
            this.$message.success("操作成功")
            setTimeout(() => {
              this.getList()
              this.submitLoading = false
              this.dialogVisible = false
            }, 1500)
          })
          .catch(() => {
            this.submitLoading = false
          })
      }
    },
    handleCancel() {
      this.submitLoading = false
      this.dialogVisible = false
    },
    async delConfirm(val) {
      //删除
      role_del({ id: val.id }).then((result) => {
        this.$message.success("操作成功")
        setTimeout(() => {
          this.getList()
        }, 1500)
      })
    },
    async merchant_menu_index() {
      //菜单列表
      config_menus().then((result) => {
        this.all_menu = result
        console.log(this.all_menu)
      })
    },
    dialogClick(type, row) {
      this.dialogType = type
      if (type == "edit") {
        this.$nextTick(() => {
          this.form = {
            id: row.id,
            name: row.name,
          }
          this.formItems = [
            {
              label: "角色名称",
              field: "name",
            },
          ]
        })
      } else if (type == "add") {
        this.$nextTick(() => {
          this.form = {
            name: "",
          }
          this.formItems = [
            {
              label: "角色名称",
              field: "name",
            },
          ]
        })
      } else {
        let checkedKeys = row.menu_ids ? row.menu_ids.split(",") : []
        for (var i = 0; i < checkedKeys.length; i++) {
          checkedKeys[i] = parseInt(checkedKeys[i])
        }
        this.$nextTick(() => {
          this.form = {
            id: row.id,
            menus: checkedKeys,
          }
        })
        this.formItems = [
          {
            label: "配置权限",
            field: "menus",
            type: "tree",
            data: this.all_menu,
            replaceFields: this.replaceFields,
          },
        ]
        this.rules = {}
      }
      this.dialogVisible = true
    },
  },
}
</script>
