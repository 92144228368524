<template>
    <div>
        <div class="table-box">
            <MyTable :isSelection="false" class="mt10" :apiUrl="ordersAfterIndex" ref="myTable" :columns="columns">
                <a-space slot="user" slot-scope="item,row">
                    <span>{{ row.user.nickname }}</span>
                </a-space>
                <a-space slot="order_status_gather" slot-scope="item,row,index">
                    <span>{{ orderStatusName[row.order_status_gather] }}</span>
                </a-space>
                <a-space slot="imgs" slot-scope="item,row">
                    <img v-for="(item, index) in row.imgs.split(',')" :key="index" style="width:30px;heigth:30px"
                        :src="item" />
                </a-space>
                <a-space slot="status" slot-scope="item,row,index">
                    <a-tag color="#87d068" v-if="row.status == 1">通过</a-tag>
                    <a-tag color="#108ee9" v-else-if="row.status == 0">未审核</a-tag>
                    <a-tag color="#f50" v-else-if="row.status == -1">拒绝</a-tag>
                </a-space>
                <a-space slot="goods_name" slot-scope="item,row">
                    <span>{{ row.ordersItems | orderGoodsName }}</span>
                </a-space>
                <a-space slot="goods_spec_desc" slot-scope="item,row">
                    <span>{{ row.ordersItems | orderSpecDesc }}</span>
                </a-space>
                <a-space slot="button" slot-scope="item,row,index" v-if="row.status == 0">
                    <a-button size="small" type="primary" @click="handleClick(row.id, 1)">通过</a-button>
                    <a-button size="small" type="danger" @click="dialogClick(row.id)">拒绝</a-button>
                    <!-- <a-popconfirm title="确定是否拒绝?" ok-text="确定" cancel-text="取消" @confirm="handleClick(-1)">
                        <a-button size="small" type="danger">拒绝</a-button>
                    </a-popconfirm> -->
                </a-space>
            </MyTable>
        </div>
        <ModelForm :visible="dialogVisible" :form="form" :rules="rules" :title="售后审核" :formItems="formItems"
            :submitLoading="submitLoading" @onSubmit="onSubmit" @handleCancel="handleCancel"></ModelForm>
    </div>
</template>
<script>
import {
    orders_after_index,
    orders_after_sale
} from "@/api/orders"
import MyTable from "@/components/myTable.vue"
import MySwitch from "@/components/MySwitch/MySwitch.vue"
import ModelForm from "@/components/ModelForm/ModelForm.vue"

export default {
    data() {
        return {
            IMGHOST: window.IMGHOST,
            ordersAfterIndex: orders_after_index,
            columns: [
                {
                    title: "订单号",
                    ellipsis: true,
                    width: 200,
                    dataIndex: "after_sale_sn",
                    key: "after_sale_sn",
                },

                {
                    title: "退货原因",
                    ellipsis: true,
                    width: 200,
                    dataIndex: "reason",
                    key: "reason",
                },
                {
                    title: "评论附件",
                    ellipsis: true,
                    dataIndex: "imgs",
                    key: "imgs",
                    width: 100,
                    scopedSlots: { customRender: "imgs" },
                },
                {
                    title: "申请金额",
                    ellipsis: true,
                    width: 200,
                    dataIndex: "apply_amount",
                    key: "apply_amount",
                },
                {
                    title: "状态",
                    ellipsis: true,
                    dataIndex: "status",
                    key: "status",
                    width: 150,
                    scopedSlots: { customRender: "status" },
                },
                {
                    title: "商品名称",
                    ellipsis: true,
                    dataIndex: "goods_name",
                    key: "goods_name",
                    width: 200,
                    scopedSlots: { customRender: "goods_name" },
                },
                {
                    title: "商品规格",
                    ellipsis: true,
                    dataIndex: "goods_spec_desc",
                    key: "goods_spec_desc",
                    width: 200,
                    scopedSlots: { customRender: "goods_spec_desc" },
                },
                {
                    title: "创建时间",
                    ellipsis: true,
                    dataIndex: "create_at",
                    key: "create_at",
                    width: 220,
                },
                {
                    title: "更新时间",
                    ellipsis: true,
                    dataIndex: "update_at",
                    key: "update_at",
                    width: 220,
                },
                {
                    title: "操作",
                    key: "operation",
                    fixed: "right",
                    width: 150,
                    ellipsis: true,
                    scopedSlots: { customRender: "button" },
                },
            ],
            loading: false,
            tableData: [],
            dialogVisible: false,
            form: {},
            rules: {},
            submitLoading: false,
            formItems: [{
                label: '拒绝原因',
                field: 'remark',
                type: 'textarea'
            }]
        }
    },
    components: {
        MyTable,
        MySwitch,
        ModelForm
    },
    filters: {
        orderGoodsName(val) {
            return val.map(item => item.goods_name).join(',')
        },
        orderSpecDesc(val) {
            return val.map(item => item.goods_spec_desc).join(',')
        }
    },
    created() {
    },
    methods: {
        handleClick(id, status) {
            comment_able({ id, status }).then((result) => {
                this.$message.success("操作成功")
                setTimeout(() => {
                    this.getList()
                }, 1500)
            })
        },
        handleCancel() {
            this.submitLoading = false
            this.dialogVisible = false
        },
        dialogClick(id) {
            this.dialogVisible = true
            this.form.id = id
        },
        handleClick(id, status) {
            orders_after_sale({ id, status }).then((result) => {
                this.$message.success("操作成功")
                setTimeout(() => {
                    this.$refs.myTable.getList()
                }, 1500)
            })
        },
        async onSubmit(form) {
            form.id = this.form.id
            form.status = -1
            orders_after_sale(form)
                .then((result) => {
                    this.$message.success("操作成功")
                    setTimeout(() => {
                        this.$refs.myTable.getList()
                        this.submitLoading = false
                        this.dialogVisible = false
                    }, 1500)
                })
                .catch(() => {
                    this.submitLoading = false
                })
        },
    }
}
</script>