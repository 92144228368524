<template>
    <div>
        <div class="table-box">
            <MyTable :isSelection="false" class="mt10" :apiUrl="commentList" ref="myTable" :columns="columns">
                <a-space slot="user" slot-scope="item,row">
                    <span>{{ row.user.nickname }}</span>
                </a-space>
                <a-space slot="goods" slot-scope="item,row">
                    <span>{{ row.goods.goods_name }}</span>
                </a-space>
                <a-space slot="imgs" slot-scope="item,row" >
                    <img v-for="(item, index) in row.imgs.split(',')" :key="index" style="width:30px;heigth:30px"
                        :src="item" @click="showImgPopup(item)"/>
                </a-space>
                <a-space slot="status" slot-scope="item,row,index">
                    <a-tag color="#87d068" v-if="row.status == 1">通过</a-tag>
                    <a-tag color="#108ee9" v-else-if="row.status == 0">未审核</a-tag>
                    <a-tag color="#f50" v-else-if="row.status == -1">拒绝</a-tag>
                </a-space>
                <a-space slot="button" slot-scope="item,row,index" v-if="row.status == 0">
                    <a-button size="small" type="primary" @click="handleClick(row.id, 1)">通过</a-button>
                    <a-button size="small" type="danger" @click="handleClick(row.id, -1)">拒绝</a-button>
                    <!-- <a-popconfirm title="确定是否拒绝?" ok-text="确定" cancel-text="取消" @confirm="handleClick(-1)">
                        <a-button size="small" type="danger">拒绝</a-button>
                    </a-popconfirm> -->
                </a-space>
            </MyTable>
        </div>
        <a-modal
        title="图片展示"
        :visible="showImgStatus"
        :footer="null"
        @cancel="showImgStatus=false"
        :width="600"
        >
            <img style="width:100%;"  :src="currentImg" />
        </a-modal>
    </div>
</template>
<script>
import {
    comment_list,
    comment_able
} from "@/api/comment"
import MyTable from "@/components/myTable.vue"
import MySwitch from "@/components/MySwitch/MySwitch.vue"
export default {
    data() {
        return {
            IMGHOST: window.IMGHOST,
            commentList: comment_list,
            columns: [
                {
                    title: "id",
                    ellipsis: true,
                    width: 100,
                    dataIndex: "id",
                    key: "id",
                },
                {
                    title: "用户名",
                    ellipsis: true,
                    width: 150,
                    dataIndex: "user",
                    key: "user",
                    scopedSlots: { customRender: "user" },
                },
                {
                    title: "商品",
                    ellipsis: true,
                    width: 150,
                    dataIndex: "goods",
                    key: "goods",
                    scopedSlots: { customRender: "goods" },
                },
                {
                    title: "评论内容",
                    width: 200,
                    dataIndex: "content",
                    key: "content",
                },
                {
                    title: "评论附件",
                    ellipsis: true,
                    dataIndex: "imgs",
                    key: "imgs",
                    width: 100,
                    scopedSlots: { customRender: "imgs" },
                },
                {
                    title: "状态",
                    ellipsis: true,
                    dataIndex: "status",
                    key: "status",
                    width: 150,
                    scopedSlots: { customRender: "status" },
                },
                {
                    title: "创建时间",
                    ellipsis: true,
                    dataIndex: "create_at",
                    key: "create_at",
                    width: 220,
                },
                {
                    title: "更新时间",
                    ellipsis: true,
                    dataIndex: "update_at",
                    key: "update_at",
                    width: 220,
                },
                {
                    title: "操作",
                    key: "operation",
                    fixed: "right",
                    width: 150,
                    ellipsis: true,
                    scopedSlots: { customRender: "button" },
                },
            ],
            loading: false,
            tableData: [],
            showImgStatus:false,
            currentImg:"",

        }
    },
    components: {
        MyTable,
        MySwitch,
    },
    created() {
    },
    methods: {
        showImgPopup(img){
            this.currentImg = img
            this.showImgStatus = true
        },
        closeImgPopup(){
            this.showImgStatus = false
        },
        handleClick(id, status) {
            comment_able({ id, status }).then((result) => {
                this.$message.success("操作成功")
                setTimeout(() => {
                    this.getList()
                }, 1500)
            })
        }
    }
}
</script>