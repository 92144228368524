<!-- 账号管理 -->
<style scoped></style>
<template>
  <div class="table-box">
    <a-table class="mt10" :scroll="{ x: '100%', y: 0 }" :data-source="list" :columns="columns">
      <a-space slot="button" slot-scope="item,row">
        <a-button size="small" type="primary" @click="dialogClick('edit', item)">编辑</a-button>
      </a-space>
    </a-table>

    <ModelForm width="82%" :visible="dialogVisible" :form="form" title="编辑" :formItems="formItems"
      :submitLoading="submitLoading" @onSubmit="onSubmit" @handleCancel="handleCancel"></ModelForm>
  </div>
</template>
<script>
import { config_index, config_edit } from "@/api/config"
import ModelForm from "@/components/ModelForm/ModelForm.vue"


export default {
  data() {
    return {
      submitLoading: false,
      dialogVisible: false,
      form: {},
      list: [],
      columns: [
        {
          title: "配置名称",
          ellipsis: true,
          width: 200,
          dataIndex: "title",
          key: "title",
        }, {
          title: "配置内容",
          ellipsis: true,
          width: 300,
          dataIndex: "value",
          key: "value",
          align: "center",
        },
        {
          title: "操作",
          key: "operation",
          fixed: "right",
          width: 150,
          ellipsis: true,
          scopedSlots: { customRender: "button" },
        }
      ],
      formItems: [],
      formItemsAll: [
        {
          label: "积分规则",
          field: "point_rule",
          type: "html",
        },
        {
          label: "优惠券规则",
          field: "coupon_rule",
          type: "html",
        },
        {
          label: "会员规格",
          field: "vip_rule",
          type: "html",
        },
        {
          label: "用户协议",
          field: "user_rule",
          type: "html",
        },
        {
          label: "隐私政策",
          field: "privacy_rule",
          type: "html",
        },
        {
          label: "第三方清单",
          field: "three_rule",
          type: "html",
        },
        {
          label: "营业执照",
          field: "business_rule",
          type: "html",
        },
      ]
    }
  },
  components: {
    ModelForm,
  },
  mounted() {
    this.geiConfig()
  },
  methods: {
    geiConfig() {
      this.list = []
      config_index({
        option: "all",
      }).then((res) => {
        this.form = res
        for (const key in res) {
          if (Object.hasOwnProperty.call(res, key)) {
            const element = res[key];
            this.formItemsAll.forEach(item => {
              if (item.field == key) {
                let obj = {
                  title: item.label,
                  key,
                  value: element
                }
                this.list.push(obj)
              }
            })
          }
        }
      })
    },
    handleCancel() {
      this.submitLoading = false
      this.dialogVisible = false
    },
    onSubmit(form) {
      this.submitLoading = true
      config_edit({
        cname: this.formItems[0].field,
        cvalue: this.form[this.formItems[0].field],
      })
      this.$message.success("操作成功")
      setTimeout(() => {
        this.geiConfig()
        this.submitLoading = false
        this.dialogVisible = false
      }, 1000)
    },
    dialogClick(type, item) {
      this.dialogVisible = true
      this.formItems = [{
        label: item.title,
        field: item.key,
        type: 'html'
      }]
    },
    editorChange(params) {
      let { html, field } = params
      this.form[field] = html
    },
  },
}
</script>
