<!-- 角色管理 -->
<style scoped>
</style>
<template>
  <div >
	<div style="background-color: #fff;padding: 16px;margin-bottom: 20px;">
		<a-button type="primary"   @click="dialogClick('add')">
		  添加
		</a-button>
		<a-table class="mt10" :row-selection="{onChange:onSelectChange}" :scroll="{ x: '100%', y: 0 }" :data-source="tableData" :columns="columns">
		<!-- <MyTable @selectRow="tableSelectRow" class="mt10" :apiUrl="apiUrl" :params="ajaxparams" ref="myTable" :columns="columns"> -->
			<a-space slot='button' slot-scope="item,row,index">
				<a-button size="small" type="primary"  @click="dialogClick('edit',row)">
				  编辑
				</a-button>
				<a-popconfirm
				    title="确定是否删除?"
				    ok-text="确定"
				    cancel-text="取消"
				    @confirm="delConfirm(row)"
				  >
					<a-button size="small" type="danger" >
					  删除
					</a-button>
				</a-popconfirm>
				<a-button size="small" type="dashed"  @click="dialogClick('menu',row)">
				  配置权限
				</a-button>
			</a-space>
		</a-table>
	</div>
	<a-modal :maskClosable="false" v-model="dialogVisible" :title="dialogType=='add'?'添加':dialogType=='edit'?'编辑':'配置权限'">
		<a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
			<a-form-item label="角色名称" v-if="dialogType!='menu'">
				<a-input v-model="form.name" placeholder="请输入角色名称" />
			</a-form-item>
			<a-form-item label="配置权限" v-else>
				<a-tree
				    checkable
					v-model="form.menus"
				    :tree-data="all_menu"
					:replace-fields="replaceFields"
				/>
			</a-form-item>
		</a-form>
	  <template slot="footer">
		<a-button key="back" @click="dialogVisible=false">
		  取消
		</a-button>
		<a-button key="submit" type="primary" :loading="submitLoading" @click="onSubmit">
		  提交
		</a-button>
	  </template>
	  
	</a-modal>
  </div>
</template>
<script>
// import MyTable from "@/components/myTable.vue"
// import { export2Excel } from '@/utils/excelFu.js'
export default {
  // components:{MyTable},
  data() {
    return {
		/*
			列表header
		*/
		columns:[
			{
			  title: '操作',
			  key: 'operation',
			  fixed: 'left',
			  width: 210,
			  ellipsis: true,
			  scopedSlots: { customRender: 'button' },
			},
			{ title: '角色名称', ellipsis: true, width: 100, dataIndex: 'name', key: 'name' },
			{ title: '创建时间', ellipsis: true,dataIndex: 'ctime', key: 'ctime',width: 220},
			{ title: '更新时间', ellipsis: true,dataIndex: 'utime', key: 'utime',width: 220},
		],
		submitLoading:false,
		dialogType:'add',
		dialogVisible:false,
		form:{
			name:'',
			menus:[]
		},
		all_menu:[],
		replaceFields: {
			key: 'id',
			title: 'name',
		},
		tableData:[],
		loading:false
    }
  },
  mounted() {
	  this.getList()
	  this.merchant_menu_index()
  },
  methods: {
	onSelectChange(val){
		console.log(val)
	},
	async getList(){
		this.loading = true
		let res = await this.$api.merchant_role_index(this.params)
		if(res.code==200){
		   let result = res.result&&res.result.list?res.result.list:res.result
		   for(let i = 0; i < result.length; i++){
			   result[i].key = i
		   }
		   this.tableData = result
		}
		this.loading = false
	},
	async onSubmit(){
		this.submitLoading = true
		let model = Object.assign({}, this.form);
		let res=null;
		if(this.dialogType=='add'){
			if(!model.name){
				this.$message.warning('请输入角色名称')
				return
			}
			res = await this.$api.merchant_role_add(model)
		}else if(this.dialogType=='edit'){
			if(!model.name){
				this.$message.warning('请输入角色名称')
				return
			}
			res = await this.$api.merchant_role_edit(model)
		}else{
			if(model.menus.length==0){
				this.$message.warning('请请选择权限')
				return
			}
			model.menus = model.menus.join(',')
			res = await this.$api.merchant_role_(model)
		}
		if(res.code==200){
			this.$message.success('操作成功');
			setTimeout(()=>{
				this.getList()
			},1500)
		}else{
			this.submitLoading = false;
			return
		}
		this.submitLoading = false;
		this.dialogVisible=false
	},
	async delConfirm(val){//删除
		let res = await this.$api.merchant_role_del({id:val.id})
		if(res.code==200){
			this.$message.success('操作成功');
			setTimeout(()=>{
				this.getList()
			},1500)
		}
	},
	async merchant_menu_index(){//菜单列表
		let res = await this.$api.merchant_menu_index()
		if(res.code==200){
			this.all_menu = this.$common.treeData(res.result);
		}
	},
    dialogClick(type,row)  {
		this.dialogType=type;
		if(type=='edit'){
			this.$nextTick(()=>{
				this.form = {
					id:row.id,
					name:row.name
				}
			})
		}else if(type=='add'){
			this.$nextTick(()=>{
				this.form = {
					name:''
				}
			})
		}else {
			let checkedKeys = row.menu_ids?row.menu_ids.split(','):[]
			for (var i = 0; i < checkedKeys.length; i++) {
				checkedKeys[i] = parseInt(checkedKeys[i])
			}
			this.$nextTick(()=>{
				this.form = {
					id:row.id,
					menus:checkedKeys
				}
			})
		}
		this.dialogVisible=true
    },
  },
}
</script>
