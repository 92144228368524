<template>
  <a-switch @click="switchClick" :checked-children="checkedText" :un-checked-children="unCheckedText"
    :defaultChecked="status == 1 ? true : false" :disabled="isDisabledSwitch" />
</template>

<script>
export default {
  props: ["id", "index", "status", "checkedText", "unCheckedText", 'isDisabledSwitch'],
  data() {
    return {
      isDisabledSwitch: false,
    }
  },
  methods: {
    async switchClick(checked) {
      this.$emit('switchClick', {
        checked: checked ? 1 : 0,
        id: this.id,
        index: this.index
      })
    },
  },
}
</script>

<style></style>