<!-- 账号管理 -->
<style scoped>
	.width150{
		width: 150px
	}
</style>
<template>
  <div >
	<div style="background-color: #fff;padding: 16px;margin-bottom: 20px;">
		<!-- 搜索条件 -->
		<a-form-model  size="small" layout="inline" :model="ajaxparams">
			<a-form-model-item label="抬头">
				<a-input  placeholder="请输入抬头" v-model="ajaxparams['1_invoice_title_like']" />
			</a-form-model-item>
			<a-form-model-item label="用户姓名">
				<a-input  placeholder="请输入用户姓名" v-model="ajaxparams['2_name_like']" />
			</a-form-model-item>
			<a-form-model-item label="用户邮箱">
				<a-input  placeholder="请输入用户邮箱" v-model="ajaxparams['1_email_like']" />
			</a-form-model-item>
			<a-form-model-item label="状态" >
				<a-select allowClear class="width150" v-model="ajaxparams['1_status']" placeholder="请选择状态" >
					<a-select-option :value="0">申请中</a-select-option>
					<a-select-option :value="1">已开票</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item >
				<a-button icon="search" @click="$refs.myTable.getList()" type="primary"> 搜索</a-button>
			</a-form-model-item>
		</a-form-model>
		<MyTable class="mt10" :apiUrl="userInvoice_index" :params="ajaxparams" ref="myTable" :columns="columns">
			<a-space slot='button' slot-scope="item,row,index">
				<a-popconfirm
					v-if="row.status==0"
				    title="确定审核通过?"
				    ok-text="确定"
				    cancel-text="取消"
				    @confirm="statusConfirm(row)" >
					<a-button size="small" type="primary" >
					  审核通过
					</a-button>
				</a-popconfirm>
			</a-space>
			<a-space slot='i_title_type' slot-scope="item,row,index">
				<span>{{row.i_title_type==1?'个人/事业单位':'企业'}}</span>
			</a-space>
			<a-space slot='status' slot-scope="item,row,index">
				<span>{{row.status==0?'申请中':'已开票'}}</span>
			</a-space>
			<a-space slot='amount' slot-scope="item,row,index">
				<span>{{row.amount/100}}</span>
			</a-space>
		</MyTable>
	</div>
	
  </div>
</template>
<script>
import imgUpload from "@/components/myUpload.vue"
import MyTable from "@/components/myTable.vue"

import { userInvoice_index,userInvoice_status} from '@/api/invoice'
import { config_index } from '@/api/config'

export default {
	components:{MyTable,imgUpload},
  data() {
    return {
      	userInvoice_index:userInvoice_index,
		IMGHOST:window.IMGHOST,
		ajaxparams:{},//请求参数
		/*
			列表header
		*/
		columns:[
			{
			  title: '操作',
			  key: 'operation',
			  fixed: 'left',
			  width: 150,
			  ellipsis: true,
			  scopedSlots: { customRender: 'button' },
			},
			{ title: '用户姓名', ellipsis: true,dataIndex: 'name', key: 'name', width: 150,scopedSlots: { customRender: 'name' }},
			{ title: '抬头类型', ellipsis: true,dataIndex: 'i_title_type', key: 'i_title_type', width: 150,scopedSlots: { customRender: 'i_title_type' }},
			{ title: '发票抬头', ellipsis: true, width: 150, dataIndex: 'invoice_title', key: 'invoice_title' },
			{ title: '税号',ellipsis: true, width: 250, dataIndex: 'tax_no', key: 'tax_no' },
			{ title: '申请金额', ellipsis: true,dataIndex: 'amount', key: 'amount',width: 150,scopedSlots: { customRender: 'amount' }},
			{ title: '手机号码', ellipsis: true,dataIndex: 'mobile', key: 'mobile',width: 150},
			{ title: '邮箱', ellipsis: true,dataIndex: 'email', key: 'email',width: 250},
			{ title: '状态', ellipsis: true,dataIndex: 'status', key: 'status',width: 150,scopedSlots: { customRender: 'status' }},
			{ title: '创建时间', ellipsis: true,dataIndex: 'create_at', key: 'create_at',width: 220},
			{ title: '更新时间', ellipsis: true,dataIndex: 'update_at', key: 'update_at',width: 220},
		],
    }
  },
  created() {
  },
  methods: {
	onSelectChange(val){
		console.log(val)
		
	},
	
	async statusConfirm(val){//删除
		userInvoice_status({id:val.id}).then((result)=>{
			this.$message.success('操作成功');
			setTimeout(()=>{
				this.$refs.myTable.getList()
			},1500)
		})
	},
  },
}
</script>
