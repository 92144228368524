<!-- 账号管理 -->
<style scoped>
.width150 {
  width: 150px;
}
</style>
<template>
  <div>
    <div style="background-color: #fff;padding: 16px;margin-bottom: 20px;">
      <!-- 搜索条件 -->
      <a-form-model
        size="small"
        layout="inline"
        :model="ajaxparams"
        @keyup.native.enter="$refs.myTable.search()"
      >
        <a-form-model-item label="提现单号">
          <a-input placeholder="请输入提现单号" v-model="ajaxparams['sn_like']" />
        </a-form-model-item>
        <a-form-model-item label="姓名">
          <a-input placeholder="请输入姓名" v-model="ajaxparams['name_like']" />
        </a-form-model-item>
        <a-form-model-item label="办理状态">
          <a-select allowClear class="width150" v-model="ajaxparams['status']" placeholder="办理状态">
            <a-select-option :value="-1">拒绝</a-select-option>
            <a-select-option :value="0">审核中</a-select-option>
            <a-select-option :value="1">打款中</a-select-option>
            <a-select-option :value="2">完成</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item>
          <a-button icon="search" @click="$refs.myTable.search()" type="primary">搜索</a-button>
        </a-form-model-item>
      </a-form-model>
      <MyTable
        class="mt10"
        :apiUrl="withdrawal_index"
        :params="ajaxparams"
        ref="myTable"
        :columns="columns"
      >
        <a-space slot="apply_amount" slot-scope="item,row">
          <span>{{ formattedAmount(row.apply_amount) }}</span>
        </a-space>
        <a-space slot="amount" slot-scope="item,row">
          <span>{{ formattedAmount(row.amount) }}</span>
        </a-space>
        <a-space slot="tax" slot-scope="item,row">
          <span>{{ formattedAmount(row.tax) }}</span>
        </a-space>

        <a-space slot="button" slot-scope="item,row,index">
          <a-button size="small" @click="dialogClick('',row)">更改备注</a-button>
          <a-popconfirm
            v-if="row.status==1"
            title="确定已打款完成?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="fieldHandle(row,2)"
          >
            <a-button size="small" type="dashed">打款完成</a-button>
          </a-popconfirm>

          <a-popconfirm
            v-if="row.status==0"
            title="确定审核通过?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="fieldHandle(row,1)"
          >
            <a-button size="small" type="primary">通过</a-button>
          </a-popconfirm>
          <a-popconfirm
            v-if="row.status==0"
            title="确定审核拒绝?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="fieldHandle(row,'-1')"
          >
            <a-button size="small" type="danger">拒绝</a-button>
          </a-popconfirm>
        </a-space>
        <a-space slot="status" slot-scope="item,row,index">
          <span>{{row.status==-1?'拒绝':row.status==0?'审核中':row.status==1?'审核通过':'打款完成'}}</span>
        </a-space>
      </MyTable>
    </div>
    <a-modal
      :maskClosable="false"
      v-model="dialogVisible"
      :title="'更改备注'"
      :confirm-loading="submitLoading"
      @ok="onSubmit"
      @cancel="handleCancel"
    >
      <a-form-model :rules="rules" ref="formModel" size="small" :model="form">
        <a-form-model-item label="备注" prop="remark">
          <a-textarea placeholder="请输入备注" v-model="form.remark" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import imgUpload from "@/components/myUpload.vue"
import MyTable from "@/components/myTable.vue"
import {
  withdrawal_index,
  withdrawal_handle,
  withdrawal_remark,
} from "@/api/withdrawal"

export default {
  components: { MyTable, imgUpload },
  data() {
    return {
      withdrawal_index: withdrawal_index,
      IMGHOST: window.IMGHOST,
      ajaxparams: {}, //请求参数
      /*
			列表header
		*/
      columns: [
        {
          title: "操作",
          key: "operation",
          fixed: "left",
          width: 210,
          ellipsis: true,
          scopedSlots: { customRender: "button" },
        },
        {
          title: "用户名称",
          ellipsis: true,
          width: 150,
          dataIndex: "name",
          key: "name",
        },
        {
          title: "提现单号",
          ellipsis: true,
          width: 150,
          dataIndex: "sn",
          key: "sn",
        },
        {
          title: "提现金额",
          ellipsis: true,
          width: 150,
          dataIndex: "apply_amount",
          key: "apply_amount",
          scopedSlots: { customRender: "apply_amount" },
        },
        {
          title: "税费",
          ellipsis: true,
          width: 150,
          dataIndex: "tax",
          key: "tax",
          scopedSlots: { customRender: "tax" },
        },
        {
          title: "到账金额",
          ellipsis: true,
          width: 150,
          dataIndex: "amount",
          key: "amount",
          scopedSlots: { customRender: "amount" },
        },
        {
          title: "银行",
          ellipsis: true,
          width: 150,
          dataIndex: "bank_name",
          key: "bank_name",
        },
        {
          title: "银行卡帐号",
          ellipsis: true,
          width: 150,
          dataIndex: "card_number",
          key: "card_number",
        },
        {
          title: "持卡人姓名",
          ellipsis: true,
          width: 150,
          dataIndex: "holder_name",
          key: "holder_name",
        },
        {
          title: "办理状态",
          ellipsis: true,
          dataIndex: "status",
          key: "status",
          width: 100,
          scopedSlots: { customRender: "status" },
        },
        {
          title: "备注信息",
          ellipsis: true,
          dataIndex: "remark",
          key: "remark",
          width: 250,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "create_at",
          key: "create_at",
          width: 220,
        },
        {
          title: "更新时间",
          ellipsis: true,
          dataIndex: "update_at",
          key: "update_at",
          width: 220,
        },
      ],
      isDisabledSwitch: false, //是否禁用Switch
      submitLoading: false,
      dialogType: "add",
      dialogVisible: false,
      form: {
        id: 0,
        remark: "",
        status: "",
      },
      roleData: [],
      loading: false,
      tableData: [],
      rules: {
        remark: [{ required: true, message: "请输入备注", trigger: "blur" }],
      },
    }
  },
  computed: {
    formattedAmount() {
      return (price) => (price / 100).toFixed(2)
    },
  },
  created() {},
  methods: {
    onChange(value, dateString) {
      console.log("Selected Time: ", value)
      console.log("Formatted Selected Time: ", dateString)
    },
    onOk(value) {
      console.log("onOK", value)
    },
    onSelectChange(val) {
      console.log(val)
    },
    handleCancel() {
      this.submitLoading = false
      this.dialogVisible = false
    },
    async onSubmit() {
      this.$refs.formModel.validate(async (valid) => {
        if (valid) {
          this.submitLoading = true
          withdrawal_remark(this.form)
            .then((result) => {
              this.$message.success("操作成功")
              setTimeout(() => {
                this.$refs.myTable.getList()
                this.submitLoading = false
                this.dialogVisible = false
              }, 1500)
            })
            .catch(() => {
              this.submitLoading = false
            })
        } else {
          return false
        }
      })
    },
    async fieldHandle(val, status) {
      //删除
      withdrawal_handle({ id: val.id, status: status }).then((result) => {
        this.$message.success("操作成功")
        setTimeout(() => {
          this.$refs.myTable.getList()
        }, 1500)
      })
    },
    dialogClick(type, row) {
      this.form = {
        id: row.id,
        remark: row.remark,
        status: row.status,
      }
      this.dialogVisible = true
    },
  },
}
</script>
