<!-- 广告管理 -->

<style scoped>
.img {
  /* width:30px; */
  height: 30px;
}
.ellipsis-1 {
  max-width: 180px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  word-break: break-all;
}
.w150 {
  width: 150px;
}
</style>
<template>
  <div class="pageCentent">
    <!-- 搜索条件 -->
    <a-form-model
      size="small"
      layout="inline"
      :model="ajaxparams"
      @keyup.native.enter="$refs.myTable.search()"
    >
      <a-form-model-item label="用户名">
        <a-input placeholder="请输入用户名" v-model="ajaxparams['1_name_like']" />
      </a-form-model-item>
      <a-form-model-item label="审核状态">
        <a-select
          style="width:150px"
          v-model="ajaxparams['1_status']"
          placeholder="请选择审核状态"
          allowClear="true"
        >
          <a-select-option :value="-1">拒绝</a-select-option>
          <a-select-option :value="0">审核中</a-select-option>
          <a-select-option :value="1">审核通过</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item>
        <a-button icon="search" @click="$refs.myTable.search()" type="primary">搜索</a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <MyTable
        class="mt10"
        :isSelection="false"
        :apiUrl="apiUrl"
        :params="ajaxparams"
        ref="myTable"
        :columns="columns"
      >
        <a-space slot="button" slot-scope="item,row,index">
          <a-popconfirm
            v-if="row.status==0"
            title="确定通过当前用户申请?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="approved(row)"
          >
            <a-button size="small" type="primary">审核通过</a-button>
          </a-popconfirm>
          <a-popconfirm
            v-if="row.status==0"
            title="确定拒绝当前用户申请?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="rejection(row)"
          >
            <a-button size="small" type="danger">审核拒绝</a-button>
          </a-popconfirm>
        </a-space>
        <a-space slot="area_code" slot-scope="item,row,index">
          <!-- <span v-if="row.area_code">{{ row.area_code.split(',')[0] }}</span> -->
          <a-tag v-for="(t,i) in (row.area_code?province_code( row.area_code):[])" :key="i">{{t}}</a-tag>
        </a-space>
        <a-space slot="back_id_card_pic" slot-scope="item,row,index">
          <img
            @click="showDialog(row.back_id_card_pic)"
            v-if="row.back_id_card_pic"
            class="img"
            :src="row.back_id_card_pic"
          />
          <span v-else>-</span>
        </a-space>
        <a-space slot="license_pic" slot-scope="item,row,index">
          <img
            @click="showDialog(row.license_pic)"
            v-if="row.license_pic"
            class="img"
            :src="row.license_pic"
          />
          <span v-else>-</span>
        </a-space>
        <a-space slot="storefront_pic" slot-scope="item,row,index">
          <img
            @click="showDialog(row.storefront_pic)"
            v-if="row.storefront_pic"
            class="img"
            :src="row.storefront_pic"
          />
          <span v-else>-</span>
        </a-space>
        <a-space slot="front_id_card_pic" slot-scope="item,row,index">
          <img
            @click="showDialog(row.front_id_card_pic)"
            v-if="row.front_id_card_pic"
            class="img"
            :src="row.front_id_card_pic"
          />
          <span v-else>-</span>
        </a-space>
        <a-space slot="status" slot-scope="item,row,index">
          <span>{{row.status==0?'审核中':row.status==1?'审核通过':'审核拒绝'}}</span>
        </a-space>
        <a-space slot="id_card" slot-scope="item,row,index">
          <span>{{$common.hiddenString(row.id_card,'********',6)}}</span>
        </a-space>
        <a-space slot="bank_account" slot-scope="item,row,index">
          <span>{{$common.hiddenString(row.bank_account,'********',6)}}</span>
        </a-space>
      </MyTable>
    </div>
    <a-modal :footer="null" v-model="dialogVisible" title="查看">
      <img style="width:100%" :src="dialogImg" alt />
    </a-modal>
  </div>
</template>
<script>
import MyTable from "@/components/myTable.vue"
import {
  userApply_index,
  userApply_rejection,
  userApply_approved,
} from "@/api/users"
import moment from "moment"
export default {
  components: { MyTable },
  data() {
    return {
      IMGHOST: window.IMGHOST,
      apiUrl: userApply_index,
      ajaxparams: {},
      dialogImg: "",
      dialogVisible: false,
      columns: [
        {
          title: "用户名",
          ellipsis: true,
          width: 120,
          fixed: "left",
          dataIndex: "name",
          key: "name",
          scopedSlots: { customRender: "name" },
        },
        {
          title: "邀请人",
          ellipsis: true,
          dataIndex: "parent_name",
          fixed: "left",
          key: "parent_name",
          width: 120,
          scopedSlots: { customRender: "parent_name" },
        },
        {
          title: "手机号码",
          ellipsis: true,
          width: 150,
          dataIndex: "mobile",
          key: "mobile",
          scopedSlots: { customRender: "mobile" },
        },
        {
          title: "省份",
          ellipsis: true,
          dataIndex: "area_code",
          key: "area_code",
          width: 80,
          scopedSlots: { customRender: "area_code" },
        },
        {
          title: "身份证号码",
          ellipsis: true,
          width: 220,
          dataIndex: "id_card",
          key: "id_card",
          scopedSlots: { customRender: "id_card" },
        },
        {
          title: "身份证正面",
          ellipsis: true,
          width: 120,
          dataIndex: "front_id_card_pic",
          key: "front_id_card_pic",
          scopedSlots: { customRender: "front_id_card_pic" },
        },
        {
          title: "身份证背面",
          ellipsis: true,
          width: 120,
          dataIndex: "back_id_card_pic",
          key: "back_id_card_pic",
          scopedSlots: { customRender: "back_id_card_pic" },
        },
        {
          title: "营业执照",
          ellipsis: true,
          width: 120,
          dataIndex: "license_pic",
          key: "license_pic",
          scopedSlots: { customRender: "license_pic" },
        },
        {
          title: "店面(公司)",
          ellipsis: true,
          width: 120,
          dataIndex: "storefront_pic",
          key: "storefront_pic",
          scopedSlots: { customRender: "storefront_pic" },
        },
        {
          title: "银行卡卡号",
          ellipsis: true,
          width: 220,
          dataIndex: "bank_account",
          key: "bank_account",
          scopedSlots: { customRender: "bank_account" },
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "status",
          key: "status",
          width: 100,
          scopedSlots: { customRender: "status" },
        },
        {
          title: "最后更新时间",
          ellipsis: true,
          dataIndex: "update_at",
          key: "update_at",
          width: 200,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "create_at",
          key: "create_at",
          width: 200,
        },
        {
          title: "操作",
          key: "operation",
          fixed: "right",
          width: 200,
          scopedSlots: { customRender: "button" },
        },
      ],
      regionTree: [],
    }
  },
  created() {},
  mounted() {
    let data = this.$db.get("regionList").data
    this.regionTree = this.$common.treeData(data, "pcode", "code", 0)
  },
  destroyed() {},
  methods: {
    moment,
    showDialog(src) {
      this.dialogImg = src
      this.dialogVisible = true
    },
    approved(row) {
      userApply_approved({ id: row.id }).then((res) => {
        this.$message.success("操作成功")
        setTimeout(() => {
          this.confirmLoading = false
          this.isModalShow = false
          this.$refs.myTable.getList()
        }, 1500)
      })
    },
    rejection(row) {
      userApply_rejection({ id: row.id }).then((res) => {
        this.$message.success("操作成功")
        setTimeout(() => {
          this.confirmLoading = false
          this.isModalShow = false
          this.$refs.myTable.getList()
        }, 1500)
      })
    },
    province_code(codes) {
      codes = codes.split(",")
      let _this = this
      let names = []
      for (let i = 0; i < codes.length; i++) {
        let result = _this.regionTree.find((item) => {
          return codes[i] == item.code
        })
        if (result) {
          names.push(result.name)
        }
      }
      return names
    },
  },
}
</script>
	