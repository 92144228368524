<template>
  <div ref="editor"></div>
</template>
<script>
import { uploader } from '@/api/common'
import axios from 'axios'
import E from 'wangeditor-antd'
export default {
  name: 'editor',
  props: ['content', "field"], //回调方法
  mounted() {
    let _this = this;
    this.$nextTick(() => {
      let editor = new E(_this.$refs.editor);
      editor.customConfig.onchange = (html) => {
        // _this.getFullText(html) //内容赋值
        _this.$emit('change', { html, field: _this.field })
      };
      editor.customConfig.uploadImgServer = '你的上传地址';
      editor.customConfig.uploadFileName = 'file';
      editor.customConfig.zIndex = 100;
      editor.customConfig.uploadImgParams = {
        from: 'editor'
      };
      editor.customConfig.customUploadImg = function (files, insert) {
        // files 是 input 中选中的文件列表
        // insert 是获取图片 url 后，插入到编辑器的方法
        console.log(files)
        // 上传代码返回结果之后，将图片插入到编辑器中
        // insert(imgUrl)
        for (let i = 0; i < files.length; i++) {
          (function (j) {
            _this.customRequest(files[j], insert)
          })(i)
        }
      }
      editor.create();
      //如果默认传递content值则渲染默认内容
      editor.txt.html(_this.content)
    })
  },
  methods: {
    uploadFile(file, insert) {
      let _this = this;
      let ossRes = _this.$db.get('ossRes')
      var formData = new FormData();
      //注意formData里append添加的键的大小写
      let keyValue = ossRes.dir + new Date().getTime() + "." + 'jpeg'
      formData.append('key', keyValue); //存储在oss的文件路径
      formData.append('OSSAccessKeyId', ossRes.accessId); //accessKeyId
      formData.append('policy', ossRes.policy); //policy
      formData.append('signature', ossRes.signature); //签名
      formData.append("file", file, file.name);
      formData.append('success_action_status', 201); //成功后返回的操作码
      axios.post(ossRes.host, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(res => {
          console.log('上传成功', res)
          if (res.status == 201) {  //上传成功 上传的路径就是要使用的路径
            // let url = ossRes.host + "/" + keyValue;
            insert(ossRes.host + "/" + keyValue)
            // _this.$emit('result',{host:ossRes.host,url:"/" + keyValue,type:_this.type})
          }
          _this.loading = false;
        })
        .catch(error => {
          console.log('上传失败', error)
          // _this.loading = false
        })
    },
    async customRequest(file, insert) {
      let _this = this;
      let getOssToken = _this.$db.get('ossRes')
      if (getOssToken && (getOssToken.expire * 1000 > new Date().getTime())) {
        this.uploadFile(file, insert)
      } else {
        uploader({
          dir: "goods",
        }).then((res) => {
          _this.$db.set('ossRes', res)
          this.uploadFile(file, insert)
        }).catch((err) => {
          console.log(err)
        })
        // ossRes = await this.$api.merchant_common_upload()
        // if(ossRes.code==200){
        // 	_this.$db.set('ossRes',ossRes)
        // }else{
        // 	this.loading = false
        // 	return
        // }
      }
    },
  }
}
</script>